import { Request, Similarity } from "../../reducers/SystemAdminReducer";

export type SystemAdminActions = IGetAllRequest | IChangeRequestState | IGetSimilarity;

export interface IGetAllRequest {
    type: SystemAdminActionTypes.GETALLREQUEST,
    requests: Array<Request>,
}

export interface IChangeRequestState {
    type: SystemAdminActionTypes.CHANGEREQUESTSTATE,
    state: number,
    id: number,
}

export interface IGetSimilarity {
    type: SystemAdminActionTypes.GETSIMILARITY,
    similarity: Similarity,
}

export enum SystemAdminActionTypes{
    GETALLREQUEST = 'GETALLREQUEST',
    CHANGEREQUESTSTATE = 'CHANGEREQUESTSTATE',
    GETSIMILARITY = 'GETSIMILARITY',
}

export interface ISystemAdminState {
    requests: Array<Request>,
    similarity: Similarity,
}