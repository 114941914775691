import * as React from 'react';
//import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Navbar from "./Navbar";
import PageNotFound from "./PageNotFound";
import { Router, Switch, Route, Redirect } from "react-router";
import { createBrowserHistory } from "history";
import AssociationsPage from "./AssociationsPage/AssociationsPage";
import SingleAssociationPage from './SingleAssociationPage/SingleAssociationPage';
//import { getAllAssociation } from './actions/AssociationActions';
import SinglePlayerPage from './SinglePlayerPage/SinglePlayerPage';
import PlayersPage from './PlayersPage/PlayersPage';
import LoginPage from './LoginPage/LoginPage';
import { connect } from 'react-redux';
import { authenticate, logout } from './actions/UserActions';
import AssociationDashboard from './AssociationDashboard/AssociationDashboard';
import RegisterForm from './PlayerRegister/RegisterForm';
import LicencesDashboard from './LicencesDashboard/LicencesDashboard';
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { User } from './reducers/UserReducer';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Alert, Color } from '@material-ui/lab';
import { NotificationTypes } from './actions/types/NotificationTypes';
import ForgotPasswordPage from './ForgotPasswordPage/ForgotPasswordPage';
import MatchResultPage from './MatchResultPage/MatchResultPage';
import MatchControlPage from './MatchControlPage/MatchControlPage';
import LeagueTablePage from './LeagueTablePage/LeagueTablePage';
import SeasonMatchesPage from './SeasonMatchesPage/SeasonMatchesPage';
import SubmittableFutureMatchesPage from './SubmittableFutureMatchesPage/SubmittableFutureMatchesPage';
import MatchSubmissionPage from './MatchSubmissionPage/MatchSubmissionPage';
import ScoreKeepingDashboard from './ScoreKeepingDashboard/ScoreKeepingDashboard';
import LicenceRequest from './LicenceRequest/LicenceRequest';

const history = createBrowserHistory();
export const direct = (url: string) => {
    history.push("/" + url);
}

interface IProps {
    user: User,
    backdropopen: boolean,
    logout: Function,
    message: string,
    severity: Color,
    hideError: Function,
    authenticate: Function,
}

interface IState {
    currentpath: string,
}

class Routes extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            currentpath: history.location.pathname,
        };
    }

    public render() {
        let loggedinlink;
        let authenticatedRoutes;
        if (this.props.user !== null && this.props.user.email !== "") {
            if (this.props.user.role === "AssociationAdmin") loggedinlink = AssociationDashboard;
            else if (this.props.user.role === "SystemAdmin") loggedinlink = LicencesDashboard;
            else if(this.props.user.role === "ScoreKeeper") loggedinlink = ScoreKeepingDashboard;
            authenticatedRoutes = [
                <Route path="/matchcontrol/:id" component={MatchControlPage} key="route-matchcontrol" />,
                <Route path="/submittablematches" component={SubmittableFutureMatchesPage} key="route-submittablematches" />,
                <Route path="/matchsubmission" component={MatchSubmissionPage} key="route-matchsubmission" />,
                <Route path="/dashboard" component={loggedinlink} key="route-dashboard" />,
                <Route path="/cp" component={ForgotPasswordPage} key="route-cp" />,
            ];
        }
        else if (this.props.user.token !== "") {
            this.props.authenticate();
        }
        return (
            <Router history={history}>
                <div>
                    <Navbar key="navbar" {... this.props} />
                    <TransitionGroup>
                        <CSSTransition
                            key={history.location.key}
                            timeout={500}
                            classNames="animate"
                        >
                            <Switch>
                                <Redirect exact={true} from="/" to="/players" />
                                <Route path="/associations" component={AssociationsPage} key="route-associations" /*getData={getAllAssociation}*/ />
                                <Route path="/association" component={SingleAssociationPage} key="route-association" />
                                <Route path="/login" component={LoginPage} key="route-login" />
                                <Route path="/players" component={PlayersPage} key="route-players" />
                                <Route path="/player" component={SinglePlayerPage} key="route-player" />
                                <Route path="/register" component={RegisterForm} key="route-register" />
                                <Route path="/licence-request" component={LicenceRequest} key="route-licence-request" />
                                <Route path="/match/:id" component={MatchResultPage} key="route-match" />
                                {authenticatedRoutes}
                                {<Route path="/leaguetable" component={LeagueTablePage} key="route-leaguetable" /> }
                                <Route path="/results" component={SeasonMatchesPage} key="route-results" />
                                <Route component={PageNotFound} />
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                    <Backdrop open={this.props.backdropopen} classes={{root: "backdrop"}}>
                        <CircularProgress classes={{ colorSecondary: "backdrop-spinner-color" }} color="secondary" />
                    </Backdrop>
                    <Snackbar open={this.props.message !== "" ? true : false} onClose={() => this.props.hideError()} autoHideDuration={10000}>
                        <Alert severity={this.props.severity}>{this.props.message}</Alert>
                    </Snackbar>
                </div>
            </Router>
        );
    }
};

function mapStateToProps(data: any) {
    return {
        user: data.UserReducer.loggedinuser,
        backdropopen: data.BackdropReducer.open,
        message: data.NotificationReducer.message,
        severity: data.NotificationReducer.severity,
    }
}

function mapActionToProps(dispatch: any) {
    return {
        logout: () => dispatch(logout()),
        hideError: () => dispatch({ type: NotificationTypes.DISAPPEAR }),
        authenticate: () => dispatch(authenticate()),
    };
};

export default connect(mapStateToProps, mapActionToProps)(Routes);