import * as React from "react";
import { connect } from "react-redux";
import { Paper, Container, Grid, Avatar, ListItem, List, Typography } from "@material-ui/core";
import { getMatchResult } from "../actions/MatchResultActions";
import { getCompetitionMatches  } from "../actions/CompetitionActions";
import { MatchResult } from "../reducers/MatchResultReducer";
import "./SeasonMatchesPage.css";
import { direct } from "../Routes";

interface IProps {
    getCompetitionMatches: Function,
    seasonMatches: Array<MatchResult>,
    getMatchResult: Function,
}

class SeasonMatchesPages extends React.Component<IProps>{

    componentDidMount() {
        this.props.getCompetitionMatches(2);
    }

    render() {
        //this.props.seasonMatches.sort((a,b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        let rows = this.props.seasonMatches.map(match => {
            return <ListItem key={match.id} button onClick={() => direct("match/" + match.id)} divider>
                <Grid container alignItems="center">
                    <Grid item xs={3} sm={1}><Avatar classes={{root: "leftside-item"}} src={match.homePicture}/></Grid>
                    <Grid item xs={9} sm={4}><Typography align="left">{match.homeTeam}</Typography></Grid>
                    <Grid item xs={12} sm={2} container>
                        <Grid item xs={12}><Typography align="center" variant="h6">{match.homeScore} : {match.awayScore}</Typography></Grid>
                        <Grid item xs={12}><Typography align="center" variant="body2" display="block">{this.getMatchDateAndTime(new Date(match.date))}</Typography></Grid>
                    </Grid>
                    <Grid item xs={9} sm={4}><Typography align="right">{match.awayTeam}</Typography></Grid>
                    <Grid item xs={3} sm={1}><Avatar classes={{root: "rightside-item"}} src={match.awayPicture}/></Grid>
                </Grid>
            </ListItem> 
        });
        return (
            <Container className="cont" maxWidth="lg">
                <Paper elevation={3}>
                    <List>{rows}</List>
                </Paper>
            </Container>
        );
    }

    getMatchDateAndTime = (date: Date) => {
        return this.addZeroToSingleDigitTime(date.getFullYear()) + "."
        + this.addZeroToSingleDigitTime(date.getMonth() + 1) + "." 
        + this.addZeroToSingleDigitTime(date.getDate()) + ". " 
        + this.addZeroToSingleDigitTime(date.getHours()) + ":" 
        + this.addZeroToSingleDigitTime(date.getMinutes());
    }
    
    addZeroToSingleDigitTime = (x: number) => {
        return x < 10 ? "0" + x : x;
    }
}

function mapStateToProps(state: any) {
    return {
        seasonMatches: state.MatchResultReducer.seasonMatches,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        getCompetitionMatches: (competitionId: number) => dispatch(getCompetitionMatches(competitionId, 100)),
        getMatchResult: (matchId: number) => dispatch(getMatchResult(matchId)),
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
    (SeasonMatchesPages);