import * as React from "react";
import { ProtectedPlayer } from "../reducers/AssociationAdminReducer";
import {
    TableRow, TableCell, Checkbox, /*, MenuItem, Select*/
    Tooltip
} from "@material-ui/core";
import { /*AddCircleOutline, CheckCircleOutlined*/ Warning } from "@material-ui/icons";
import "./AssociationDashboard.css";

interface IProps {
    checkboxes: Array<boolean>,
    players: Array<ProtectedPlayer>,
    handleCheckboxClick: Function,
    //handleImageUploadForPlayer: Function,
    //selectedsports: Array<number>,
    //handleSportSelectionChange: Function,
}

interface IState {
    sportmedicallicencedialogopen: boolean,
}

class DashboardTableContent extends React.Component<IProps, IState>{
    constructor(props: any) {
        super(props);
        this.state = {
            sportmedicallicencedialogopen: false,
        }
    }

    render() {
        let rows = this.props.players?.map((player, i) =>
            <TableRow key={"tablerow" + player.id}>
                <TableCell padding="checkbox">
                    <Checkbox checked={this.props.checkboxes[i]} onChange={e => this.props.handleCheckboxClick(i, e.currentTarget.checked)} />
                </TableCell>
                <TableCell><div className="nonclickable">{"MRKSZ/" + player.id}</div></TableCell>
                <TableCell><div className="nonclickable">{player.lastname} {player.firstname}</div></TableCell>
                {/*<TableCell><div className="nonclickable" onClick={() => this.setState({ sportmedicallicencedialogopen: true })}>{player.sportsmedicallicense.getTime() + 31556952000 > new Date().getTime() ? player.sportsmedicallicense.toLocaleDateString() : "Lejárt"}</div></TableCell>*/}
                {/*<TableCell>
                    <Select
                        value={this.props.selectedsports[i]? this.props.selectedsports[i] : 0}
                        onChange={(e: any) => this.props.handleSportSelectionChange(e.target.value, i)}
                        >
                        <MenuItem value={0}>ULT</MenuItem>
                        <MenuItem value={1}>DG</MenuItem>
                        <MenuItem value={2}>FST</MenuItem>
                    </Select>
                </TableCell>*/}
                <TableCell><div className="nonclickable">{player.licences.length > 0 ? player.licences.find(x => x.sport === 0 /*this.props.selectedsports[i]*/)?.validity.toLocaleDateString() : ""}</div></TableCell>
                <TableCell>
                    <div className="nonclickable">
                        {player.confirmedIdentity ? "" :
                            <Tooltip title="Adatok nem közhitelesek. Közhitelesítéshez vegye fel a kapcsolatot a versenybizottsággal. Közhitelesítésig versenyengedély nem igényelhető.">
                                <Warning color="error" />
                            </Tooltip>}
                    </div>
                </TableCell>
                {/*<TableCell>{player.imageURL? <CheckCircleOutlined className="clickable" color="action" onClick={e => this.props.handleImageUploadForPlayer(player.id)}/> : <AddCircleOutline color="primary" className="clickable" onClick={e => this.props.handleImageUploadForPlayer(player.id)}/>}</TableCell>*/}

            </TableRow>
        )
        return (
            rows
        )
    }
}

export default DashboardTableContent;