import React from "react";
import { Container, Grid, FormLabel, TextField, FormControl, RadioGroup, FormControlLabel, Radio, MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ValidationError } from "./RegisterForm";
import settlementLookupTable from "../settlements.json"

interface IProps {
    handleChange: Function,
    date: string,
    place: string,
    gender: string,
    reportErrorState: Function,
    validationerrors: Map<string, ValidationError>
}

interface IState {
    settlements: string[],
}

class BirthdataForm extends React.Component<IProps, IState> {
    constructor(props: IProps){
        super(props);
        this.state = {
            settlements: []
        }
    }

    generateRange = (startnumber: number, endnumber: number) => {
        let items: Array<JSX.Element> = new Array<JSX.Element>();
        for (let i = startnumber; i <= endnumber; i++) {
            items.push(<MenuItem key={startnumber + "-" + endnumber + "-" + i} value={i}>{i}</MenuItem>);
        }
        return items;
    }

    validatebirthplace = (propertyname: string, value: string) => {
        if (value === "" || value === null) {
            this.props.reportErrorState("birthplaceerror", true, "Mező kitöltése kötelező");
        }
        else if (/[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöőõøùúûüűųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖŐÕØÙÚÛÜŰŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]/.test(value)) {
            this.props.reportErrorState("birthplaceerror", true, "Nem megfelelő karakter");
        }
        else if (value.length > 30) {
            this.props.reportErrorState("birthplaceerror", true, "Túl hosszú név");
        }
        else {
            this.props.reportErrorState("birthplaceerror", false, "");
        }

        if(value) this.setState({settlements: this.settlementLookupByName(value)});
        this.props.handleChange(propertyname, value);
    }

    settlementLookupByName = (name: string) => {
        return settlementLookupTable
            .filter((x: {postcode: number, settlement: string}) => x.settlement.toLowerCase().includes(name.toLowerCase()))
            .map((y: {postcode: number, settlement: string}) =>  y.settlement)
            .filter((value, index, array) => array.indexOf(value) === index);
    }

    render() {
        return (
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={10} sm={6}>
                        <TextField
                            label="Születési idő"
                            value={this.props.date}
                            onChange={e => this.props.handleChange("birthdate", e.target.value)}
                            name="birthplace"
                            type="date"
                            color="primary" />
                    </Grid>
                </Grid>
                <Grid container spacing={8}>
                    <Grid item xs={10} sm={6}>
                        <Autocomplete
                            autoComplete
                            includeInputInList
                            options={this.state.settlements}
                            value={this.props.place}
                            onChange={(e: any, v: any) => this.validatebirthplace("birthplace", v)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    fullWidth
                                    label="Születési hely"
                                    onChange={e => this.validatebirthplace("birthplace", e.currentTarget.value)}
                                    color="primary"
                                    error={this.props.validationerrors.get("birthplaceerror")?.value}
                                    helperText={this.props.validationerrors.get("birthplaceerror")?.message} />} />
                    </Grid>
                </Grid>
                <Grid container spacing={8}>
                    <Grid item sm={12}>
                        <FormControl>
                            <FormLabel component="legend" color="primary">Nem</FormLabel>
                            <RadioGroup value={this.props.gender} onChange={e => this.props.handleChange("gender", e.currentTarget.value)} row>
                                <FormControlLabel value="female" control={<Radio />} label="Nő" />
                                <FormControlLabel value="male" control={<Radio />} label="Férfi" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

/*const towns = ['Budapest', 'Győr', 'Debrecen', 'Szeged', 'Miskolc', 'Pécs', 'Nyíregyháza', 'Kecskemét', 'Székesfehérvár',
    'Szombathely', 'Szolnok', 'Érd', 'Tatabánya', 'Sopron', 'Kaposvár', 'Veszprém', 'Békéscsaba', 'Zalaegerszeg', 'Eger'];
*/
export default BirthdataForm;