import axios from "axios";
import { Dispatch } from "redux";
import { connectionUrl } from "../auth";
import { MatchResult } from "../reducers/MatchResultReducer";
import { MatchResultActionTypes } from "./types/MatchResultActionTypes";
import { NotificationTypes } from "./types/NotificationTypes";
import { HttpTransportType, HubConnectionBuilder, HubConnectionState, signalMiddleware, withCallbacks } from "redux-signalr"
import { addMiddleware, removeMiddleware } from 'redux-dynamic-middlewares'

export const getMatchResult = (matchId: number) => {
    
    return async (dispatch: Dispatch, getState: Function) => {
        await axios.get(`${connectionUrl}/match/${matchId}`)
            .then(
                response => { 
                        let data = transformDate(response.data);
                        //if(data.date.toDateString() === new Date().toDateString())
                            if (connection.state !== HubConnectionState.Connected) {
                                callbacks.add(`match${matchId}`, (matchResult: MatchResult) => (dispatch: Dispatch) => {
                                    dispatch({ type: MatchResultActionTypes.GETMATCHRESULT, matchResult: transformDate(matchResult) });
                                })
                                
                                addMiddleware(signal);

                                connection
                                  .start()
                                  .then()
                                  .catch((err) => console.error(err.toString()));
                              }
                        dispatch(success(data));
                    }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(matchResult: MatchResult) { return { type: MatchResultActionTypes.GETMATCHRESULT, matchResult } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const stopConnection = (matchId: number) => {
    connection.stop();
    removeMiddleware(signal);
    callbacks.callbackMap.delete(`match${matchId}`);
}

function transformDate(match: MatchResult) {
    match.date = new Date(match.date);
    match.startTime = new Date(match.startTime);
    match.endTime = new Date(match.endTime);
    return match;
}

const connection = new HubConnectionBuilder()
    .withUrl(`${connectionUrl}/matchhub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
    .build();

const callbacks = withCallbacks();

export const signal = signalMiddleware({
    callbacks,
    connection,
    shouldConnectionStartImmediately: false  
});

export const getSeasonMatches = (season: number) => {
    return async (dispatch: Dispatch) => {
        await axios.get(`${connectionUrl}/match/seasonmatches/${season}`)
            .then(
                response => { dispatch(success(response.data)); }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(matches: Array<MatchResult>) { return { type: MatchResultActionTypes.GETSEASONMATCHES, matches } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const getTodaysMatches = () => {
    return async (dispatch: Dispatch) => {
        await axios.get(`${connectionUrl}/match/today/`)
            .then(
                response => { dispatch(success(response.data)); }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(matches: Array<MatchResult>) { return { type: MatchResultActionTypes.GETMATCHESTODAY, matches } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}