import * as React from 'react';
import './Login.css';
import { Grid, Button, TextField, Container/*, Typography*/ } from '@material-ui/core';
//import { direct } from '../Routes';


interface IState {
    email: string,
    password: string,
}

interface IProps {
    login: Function,
    backdropopen: boolean,
}

class LoginForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };
    }

    handleChange = (event: any) => {
        if (event.target.name === "email") {
            this.setState({
                email: event.target.value
            });
        }
        else if (event.target.name === "password") {
            this.setState({ password: event.target.value });
        }
    }

    handleSubmit = () => {
        this.props.login(this.state.email, this.state.password)
    }

    render() {
        return (
            <Container>
                <form className="loginform" onSubmit={this.handleSubmit}>
                    <Grid container direction="column" alignItems="center">
                        <TextField
                            autoFocus
                            classes={{ root: "field" }}
                            name="email"
                            label="E-mail"
                            type="email"
                            variant="outlined"
                            color="primary"
                            value={this.state.email}
                            onInput={(event) => this.handleChange(event)}
                        />
                        <TextField
                            classes={{ root: "field" }}
                            name="password"
                            label="Jelszó"
                            type="password"
                            variant="outlined"
                            color="primary"
                            value={this.state.password}
                            onInput={(event) => this.handleChange(event)}
                        />
                        <Button onClick={this.handleSubmit} variant="outlined" color="primary">Bejelentkezés</Button>
                        {/*<Typography variant="subtitle2" style={{cursor: "pointer", marginTop: "5px"}} color="secondary" onClick={() => direct("cp")}>Elfelejtett jelszó</Typography>*/}
                    </Grid>
                </form>
            </Container>
        );
    }
}

export default LoginForm;

