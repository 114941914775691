import { Container, Paper, Grid, TextField, /*InputLabel, MenuItem, Select, */Button } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { sendNonAssociationRequest } from "../actions/PlayerActions";
import { LicenceRequestingPlayer } from "../reducers/SinglePlayerReducer";
import "./LicenceRequest.css"

interface IState {
    lastname: string,
    firstname: string,
    mothersfirstname: string,
    motherslastname: string,
    birthplace: string,
    birthdate: string,
    sport: number
}

interface IProps {
    requestLicence: Function
}

class LicenceRequest extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            lastname: "",
            firstname: "",
            mothersfirstname: "",
            motherslastname: "",
            birthplace: "",
            birthdate: "2000-01-01",
            sport: 0,
        }
    }

    handleSubmit = () => {
        let player: LicenceRequestingPlayer = {
            firstName: this.state.firstname,
            lastName: this.state.lastname,
            mothersFirstName: this.state.mothersfirstname,
            mothersLastName: this.state.motherslastname,
            birthdate: new Date(this.state.birthdate),
            birthplace: this.state.birthplace,
            sport: this.state.sport

        }
        this.props.requestLicence(player)
    }

    render() {
        return (
            <Container classes={{ root: "container-alignment" }} maxWidth="sm">
                <Paper>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                classes={{ root: "inputfield" }}
                                name="lastname"
                                value={this.state.lastname}
                                onChange={e => this.setState({ lastname: e.target.value })}
                                color="primary"
                                label="Vezetéknév" />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                classes={{ root: "inputfield" }}
                                name="firstname"
                                value={this.state.firstname}
                                onChange={e => this.setState({ firstname: e.target.value })}
                                color="primary"
                                label="Keresztnév" />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                classes={{ root: "inputfield" }}
                                label="Anyja vezetékneve"
                                value={this.state.motherslastname}
                                onChange={e => this.setState({ motherslastname: e.target.value })}
                                name="motherslastname"
                                color="primary" />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                classes={{ root: "inputfield" }}
                                label="Anyja keresztneve"
                                value={this.state.mothersfirstname}
                                onChange={e => this.setState({ mothersfirstname: e.target.value })}
                                name="mothersfirstname"
                                color="primary" />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                classes={{ root: "inputfield" }}
                                label="Születési hely"
                                value={this.state.birthplace}
                                onChange={e => this.setState({ birthplace: e.target.value })}
                                name="birthplace"
                                color="primary" />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                classes={{ root: "inputfield" }}
                                label="Születési idő"
                                value={this.state.birthdate}
                                onChange={e => this.setState({ birthdate: e.target.value })}
                                name="birthplace"
                                type="date"
                                color="primary" />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            {/*<div className="inputfield">
                                <InputLabel id="sport-select-label" shrink>Sportág</InputLabel>
                                <Select
                                    labelId="sport-select-label"
                                    id="sport-select"
                                    value={this.state.sport}
                                    onChange={e => this.setState({ sport: e.target.value as number })}
                                >
                                    <MenuItem value={0}>ultimate</MenuItem>
                                    <MenuItem value={1}>disc golf</MenuItem>
                                    <MenuItem value={2}>freestyle</MenuItem>
                                </Select>
                            </div>*/}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Button classes={{ root: "inputfield" }} variant="outlined" color="primary" onClick={this.handleSubmit}>Küldés</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        );
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        requestLicence: (player: LicenceRequestingPlayer) => dispatch(sendNonAssociationRequest(player))
    }
}

export default connect(
    null,
    mapActionToProps)
    (LicenceRequest);