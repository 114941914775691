import * as React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, Timeline } from '@material-ui/lab';
import { Adjust, Timer } from '@material-ui/icons';
import { Point, Team, Timeout } from '../reducers/MatchResultReducer';

interface IProps {
    awayPoints: Array<Point>,
    homePoints: Array<Point>,
    timeouts: Array<Timeout>,
    homeTeam: string,
    awayTeam: string,
    matchEnded: number,
}

interface IState {
}

class TimeLine extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {

        }
    }

    createTimeLine = (awayPoints: Array<Point>, homePoints: Array<Point>, timeouts: Array<Timeout>) => {
        awayPoints.forEach(point => point.team = Team.AWAY);
        homePoints.forEach(point => point.team = Team.HOME);
        let points = awayPoints.concat(homePoints);
        timeouts.forEach(timeout => points.push({
            id: 0, scorer: "Időkérés", assist: "",
            minute: timeout.minute, team: timeout.team === this.props.homeTeam ? Team.HOME : Team.AWAY
        }));
        points = points.sort((a, b) => a.minute - b.minute);

        let timeline = points.map(point => {
            return <Timeline align={point.team === Team.HOME ? "right" : "left"} classes={{ root: "marginaftertimeline" }} key={"timeline-element" + point.minute}>
                {point.scorer !== null ? <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography variant="body1" color="textSecondary" classes={{ root: "timeline-element-minute-text" }}>
                            {point.minute + "'"}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color={point.team === Team.HOME ? "primary" : "secondary"}>
                            {point.scorer === "Időkérés" ? <Timer /> : <Adjust />}
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} classes={{ root: "timeline-element-paper" }}>
                            <Typography variant="h6" component="h1">
                                {point.scorer}
                            </Typography>
                            <Typography>{point.assist !== point.scorer ? point.assist : "Callahan"}</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem> : ""}
            </Timeline>
        });
        return timeline;
    }

    public render() {
        return (
            <Paper elevation={0} classes={{root: "timeline-container"}}>
                {this.createTimeLine(this.props.awayPoints, this.props.homePoints, this.props.timeouts)}
                {this.props.matchEnded > 1 ? <Timeline align="right" classes={{ root: "marginaftertimeline" }}>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                                {this.props.matchEnded}'
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <Timer />
                            </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={3} classes={{ root: "timeline-element-paper" }}>
                                <Typography variant="h6" component="h1">
                                    Mérkőzés véget ért
                                </Typography>
                                <Typography></Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline> : ""}
            </Paper>
        );
    }
}

export default TimeLine;