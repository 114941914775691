import { MatchResult } from "../../reducers/MatchResultReducer";

export type MatchControllerActions = IGetMatchResult

export interface IGetMatchResult {
    type: MatchControllerActionTypes.GETMATCHRESULT,
    matchResult: MatchResult,
}

export enum MatchControllerActionTypes{
    GETMATCHRESULT = 'GETMATCHRESULT',
}

export interface IMatchControllerState {
    match: MatchResult
}