import * as React from 'react';
import { Grid, TextField, InputAdornment, Container} from '@material-ui/core';
import ImgCard from './ImgCard';
import { Player } from '../reducers/PlayerReducer';
import { Search } from '@material-ui/icons';
import './PlayersPage.css';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import { Skeleton } from '@material-ui/lab';

interface IProps {
    players: Array<Player>,
    getPlayer: Function,
    getAllPlayer: Function,
    findPlayers: Function,
    pages: number,
}

interface IState {
    searchexpression: string,
    pagenumber: number,
}

class CardHolder extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            searchexpression: "",
            pagenumber: 1,
        }
    }

    handleChange = (value: string) => {
        this.setState({ 
            searchexpression: value,
            pagenumber: 1,
        });
        this.props.findPlayers(1, value);
    }

    handlePageSelect = (value: number) => {
        this.setState({pagenumber: value});
        this.props.findPlayers(value, this.state.searchexpression);
    }

    public render() {
        var cardlist = [];
        if (this.props.players !== undefined && this.props.players.length !== 0) {
            for (let i = 0; i < this.props.players.length; i++) {
                let card = <ImgCard key={"player" + i} player={this.props.players[i]} getPlayer={this.props.getPlayer} />
                //let matchingfirstname = this.props.players[i].firstname.toLowerCase().match(this.state.searchexpression.toLowerCase());
                //let matchinglastname = this.props.players[i].lastname.toLowerCase().match(this.state.searchexpression.toLowerCase())
                //if (matchingfirstname != null || matchinglastname != null) {
                    cardlist.push(card);
                //}
            }
        }
        else for(let i = 0; i < 6; i++){ cardlist.push(<Skeleton key={"playerskeleton" + i}><ImgCard key={"player" + i}  player={new Player()} getPlayer={() => {}}/></Skeleton>);} //<ImgCard key={"player" + i}  player={new Player()} getPlayer={() => {}}/>
        return (
            <Container>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={10}>
                        <TextField
                            classes={{ root: "searchfield" }}
                            margin="normal"
                            fullWidth
                            label="Keresés játékosok nevében"
                            variant="outlined"
                            color="primary"
                            value={this.state.searchexpression}
                            onChange={e => this.handleChange(e.currentTarget.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid data-testid="cardlist" container justifyContent="space-evenly">
                    {cardlist}
                </Grid>
                <Pagination classes={{ul: "pagination"}} count={this.props.pages} size="large" page={this.state.pagenumber} onChange={(e,p) => this.handlePageSelect(p)}/>
            </Container>
        );
    }
}

export default CardHolder;