import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import Routes from './Routes';
import configureStore from './Store';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import { theme } from './theme';

interface IProps { store: Store; }

const Root: React.FC<IProps> = props => {
    return (
        <Provider store= {props.store}>
            <ThemeProvider theme={theme}>
                <StylesProvider injectFirst>
                    <Routes />
                </StylesProvider>
            </ThemeProvider>
        </Provider>
    );
};

ReactDOM.render(<Root store={configureStore()} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
