import axios from "axios";
import { Dispatch } from "redux";
import { connectionUrl, requestOptions } from "../auth";
import { IGetSimilarity, SystemAdminActionTypes } from "./types/SystemAdminActionTypes";
import { Request, Similarity } from "../reducers/SystemAdminReducer";
import { ProtectedPlayer } from "../reducers/AssociationAdminReducer";
import { NotificationTypes } from "./types/NotificationTypes";

export const changeRequestState = (state: number, id: number, reason: string) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.put(`${connectionUrl}/systemadmin/request/${id}`, {state: state, reason: reason} ,requestOptions(getState()))
            .then(
                response => {dispatch(success(state, id));}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(state: number, id: number) {return { type: SystemAdminActionTypes.CHANGEREQUESTSTATE, state, id} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const getRequests = (type: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.get(`${connectionUrl}/systemadmin/requests?type=${type}`, requestOptions(getState()))
            .then(
                response => {dispatch(success(transformDate(response.data)));}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(requests: Array<Request>) {return { type: SystemAdminActionTypes.GETALLREQUEST, requests} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

function transformDate(jsonrequests: Array<Request>) {
    let requests = new Array<Request>();
    jsonrequests.forEach(
        function (jsonrequest){
            let request: Request = {
                ...jsonrequest,
                creationdate: new Date(jsonrequest.creationdate),
            }
            requests.push(request);
        }
    );
    return requests;
}

export const getSimilarPlayers = (requestid: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.get(`${connectionUrl}/systemadmin/similarity/${requestid}`, requestOptions(getState()))
            .then(
                response => dispatch(success(transformSimilarityPlayersDate(response.data)))
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(similarity: Similarity) : IGetSimilarity {return { type: SystemAdminActionTypes.GETSIMILARITY, similarity: similarity} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

function transformSimilarityPlayersDate(jsonsimilarity: Similarity) {
    
    let similarPlayer: ProtectedPlayer = {
        ...jsonsimilarity.similarPlayer,
        birthdate: new Date(jsonsimilarity.similarPlayer.birthdate),
    }

    let registeringPlayer: ProtectedPlayer = {
        ...jsonsimilarity.registeringPlayer,
        birthdate: new Date(jsonsimilarity.registeringPlayer.birthdate),
    }

    let similarity: Similarity = {
        ...jsonsimilarity,
        registeringPlayer: registeringPlayer,
        similarPlayer: similarPlayer,
    }

    return similarity;
}

export const changeMatchData = (id: number, awayTeam: number, homeTeam: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.put(`${connectionUrl}/systemadmin/changematch/`, {id, homeTeam, awayTeam} ,requestOptions(getState()))
            .then(
                response => {dispatch(success())}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success() {return { type: NotificationTypes.SUCCESS} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}