import React from 'react';
import LoginForm from './LoginForm';
import { connect } from 'react-redux';
import { login } from '../actions/UserActions';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const LoginPage: React.FC = (props: any) => {


    return (
        <div>
            <LoginForm {...props}></LoginForm>
            <Snackbar open={props.error !== ""? true : false} onClose={props.hideError} autoHideDuration={6000}>
                <Alert severity="error">{props.error}</Alert>
            </Snackbar>
        </div>
    );
}

function mapStateToProps(state: any) {
    return {
        error: state.UserReducer.error,
    }
}

const mapActionsToProps = (dispatch: any) => {
    return {
        login: (email: string, password: string) => dispatch(login(email, password)),
        hideError: () => dispatch({ type: "HIDE_ERROR" }),
    }
};


export default connect(
    mapStateToProps,
    mapActionsToProps
)(LoginPage);