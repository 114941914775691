import * as React from "react";
import { connect } from "react-redux";
import { Paper, Container, Grid, ListItem, List, Typography, Avatar } from "@material-ui/core";
import { getTodaysMatches } from "../actions/MatchResultActions";
import "./ScoreKeepingDashboard.css"
import { direct } from "../Routes";
import { MatchResult } from "../reducers/MatchResultReducer";

interface IProps {
    getMatches: Function,
    matches: Array<MatchResult>,
    getMatchData: Function
}

class ScoreKeeperDashboard extends React.Component<IProps>{

    componentDidMount() {
        this.props.getMatches();
    }

    navigateToMatchControl = (id: number) => {
        direct(`matchcontrol/${id}`);
    }

    render() {
        return (
            <Container className="cont" maxWidth="lg">
                <Paper elevation={3}>
                    <List>{this.props.matches?.map(match => {
                        return <ListItem key={match.id} button onClick={() => direct(`matchcontrol/${match.id}`)} divider>
                            <Grid container alignItems="center">
                                <Grid item xs={3} sm={1}><Avatar classes={{ root: "leftside-item" }} src={match.homePicture} /></Grid>
                                <Grid item xs={9} sm={4}><Typography align="left">{match.homeTeam}</Typography></Grid>
                                <Grid item xs={12} sm={2}><Typography align="center">{match.homeScore} : {match.awayScore}</Typography></Grid>
                                <Grid item xs={9} sm={4}><Typography align="right">{match.awayTeam}</Typography></Grid>
                                <Grid item xs={3} sm={1}><Avatar classes={{ root: "rightside-item" }} src={match.awayPicture} /></Grid>
                            </Grid>
                        </ListItem>
                    })}</List>
                </Paper>
            </Container>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        matches: state.MatchResultReducer.todaysMatches,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        getMatches: () => dispatch(getTodaysMatches())
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
    (ScoreKeeperDashboard);