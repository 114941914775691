import * as React from "react";
import { connect } from "react-redux";
import { Paper, Container, Button, Grid, Typography } from "@material-ui/core";
import { addGoal, addTimeout, deleteGoal, deleteTimeout, endMatch, getControllerMatchResult, startMatch } from "../actions/MatchControllerActions";
import { MatchResult, PlayerAppearance } from "../reducers/MatchResultReducer";
import AddGoalDialog from "./AddGoalDialog";
import GoalLists from "./GoalLists";
import Scores from "../MatchResultPage/Scores";
import AddTimeoutDialog from "./AddTimeoutDialog";
import TimeoutList from "./TimeoutLists";
import ConfirmDialog from "./ConfirmDialog";

interface IProps {
    startMatch: Function,
    endMatch: Function,
    getMatchResult: Function,
    matchResult: MatchResult,
    addGoal: Function,
    deleteGoal: Function,
    addTimeout: Function,
    deleteTimeout: Function,
    match: any,
}

interface IState {
    showAddGoalDialog: boolean,
    lineUp: Array<PlayerAppearance>,
    showAddTimeoutDialog: boolean,
    confirmDialog: boolean,
    confirmAction: Function,
    confirmParam: number,
}

class MatchControlPage extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = {
            showAddGoalDialog: false,
            lineUp: Array<PlayerAppearance>(),
            showAddTimeoutDialog: false,
            confirmDialog: false,
            confirmAction: () => {},
            confirmParam: 0,
        }
    }

    componentDidMount() {
        this.props.getMatchResult(this.props.match.params.id);
    }

    /*componentWillUnmount = () => {
        stopConnection(this.props.matchResult.id);
    }*/

    render() {
        let minute = Math.floor((new Date().getTime() - this.props.matchResult?.startTime?.getTime()) / 60000) + 1;
        return (
            this.props.matchResult ? <Container className="cont" maxWidth="lg">
                <Paper elevation={3}>
                    <Grid container justifyContent="space-evenly">
                        <Grid item xs={4}><Typography variant="h6" align="center">{this.props.matchResult.homeTeam}</Typography></Grid>
                        <Grid item xs={4}><Typography variant="h6" align="center">{this.props.matchResult.awayTeam}</Typography></Grid>
                    </Grid>
                    <Scores homeScore={this.props.matchResult.homeScore}
                        awayScore={this.props.matchResult.awayScore}
                        startTime={this.props.matchResult.startTime}
                        endTime={this.props.matchResult.endTime}
                        date={this.props.matchResult.date} />
                    <Grid container justifyContent="space-evenly" spacing={2}>
                        <Grid item><Button variant="outlined" disabled={new Date("0001-01-01T00:00:00").getFullYear() === this.props.matchResult.startTime?.getFullYear() || new Date("0001-01-01T00:00:00").getFullYear() !== this.props.matchResult.endTime?.getFullYear()} color="primary" onClick={() => this.setState({ showAddGoalDialog: true, lineUp: this.props.matchResult.homeLineUp })}>Hazai pont</Button></Grid>
                        <Grid item><Button variant="outlined" disabled={new Date("0001-01-01T00:00:00").getFullYear() === this.props.matchResult.startTime?.getFullYear() || new Date("0001-01-01T00:00:00").getFullYear() !== this.props.matchResult.endTime?.getFullYear()} color="primary" onClick={() => this.setState({ showAddTimeoutDialog: true })}>Időkérés</Button></Grid>
                        <Grid item>
                            {new Date("0001-01-01T00:00:00").getFullYear() === this.props.matchResult.startTime?.getFullYear() ?
                                <Button variant="outlined" color="primary" onClick={() => this.props.startMatch(this.props.matchResult.id)}>Mérkőzés kezdése</Button> :
                                new Date("0001-01-01T00:00:00").getFullYear() === this.props.matchResult.endTime?.getFullYear() ?
                                    <Button variant="outlined" color="primary" onClick={() => this.props.endMatch(this.props.matchResult.id)}>Mérkőzés befejezése</Button> : ""
                            }
                        </Grid>
                        <Grid item><Button variant="outlined" disabled={new Date("0001-01-01T00:00:00").getFullYear() === this.props.matchResult.startTime?.getFullYear() || new Date("0001-01-01T00:00:00").getFullYear() !== this.props.matchResult.endTime?.getFullYear()} color="primary" onClick={() => this.setState({ showAddGoalDialog: true, lineUp: this.props.matchResult.awayLineUp })}>Vendég pont</Button></Grid>
                    </Grid>
                </Paper>
                <GoalLists
                    homeGoals={this.props.matchResult.homeGoals.sort((a, b) => b.minute - a.minute)}
                    awayGoals={this.props.matchResult.awayGoals.sort((a, b) => b.minute - a.minute)}
                    clickGoal={(goalId: number) => this.setState({confirmDialog: true, confirmAction: this.props.deleteGoal, confirmParam: goalId})} />
                <TimeoutList
                    timeouts={this.props.matchResult.timeouts.sort((a, b) => b.minute - a.minute)}
                    clickTimeout={(timeoutId: number) => this.setState({confirmDialog: true, confirmAction: this.props.deleteTimeout, confirmParam: timeoutId})} />
                <AddGoalDialog
                    open={this.state.showAddGoalDialog}
                    lineUp={this.state.lineUp}
                    minute={minute}
                    handleClose={() => this.setState({ showAddGoalDialog: false })}
                    addGoal={(scorer: number, assist: number, minute: number) => this.props.addGoal(this.props.matchResult.id, scorer, assist, minute)} />
                <AddTimeoutDialog
                    open={this.state.showAddTimeoutDialog}
                    minute={minute}
                    handleClose={() => this.setState({ showAddTimeoutDialog: false })}
                    addTimeout={(team: string, minute: number) => this.props.addTimeout(this.props.matchResult.id, team, minute)}
                    homeTeam={this.props.matchResult.homeTeam}
                    awayTeam={this.props.matchResult.awayTeam} />
                <ConfirmDialog
                    open={this.state.confirmDialog}
                    handleClose={() => this.setState({ confirmDialog: false })}
                    confirmAction={() => this.state.confirmAction(this.state.confirmParam)} />
            </Container> : ""
        );
    }
}

function mapStateToProps(state: any, props: IProps) {
    return {
        matchResult: state.MatchControlReducer.match,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        startMatch: (id: number) => dispatch(startMatch(id)),
        endMatch: (id: number) => dispatch(endMatch(id)),
        getMatchResult: (matchId: number) => dispatch(getControllerMatchResult(matchId)),
        addGoal: (matchId: number, scorer: number, assist: number, minute: number) => dispatch(addGoal(matchId, scorer, assist, minute)),
        deleteGoal: (goalId: number) => dispatch(deleteGoal(goalId)),
        addTimeout: (matchId: number, team: string, minute: number) => { dispatch(addTimeout(matchId, team, minute)) },
        deleteTimeout: (timeoutId: number) => dispatch(deleteTimeout(timeoutId))
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
    (MatchControlPage);