import * as React from "react";
import { connect } from "react-redux";
import { Paper, Container, Table, TableRow, TableCell, TableBody, TableHead, Checkbox, TableContainer, Button, TableFooter, TablePagination, IconButton } from "@material-ui/core";
import { getPlayers, sendRequest, /*getAssociation, updateAssoiationData*/ } from "../actions/AssociationAdminActions";
import { ProtectedPlayer } from "../reducers/AssociationAdminReducer";
import NewLicenceDialog from "./NewLicenceDialog";
import DashboardTableContent from "./DashboardTableContent";
//import { uploadImage } from "../actions/AssociationAdminActions";
//import ImgCropper from "./ImgCropper";
//import AssociationData from "./AssociationData";
//import { Association } from "../reducers/AssociationReducer";
import { KeyboardArrowLeft, KeyboardArrowRight, LastPage, FirstPage } from "@material-ui/icons";
import "./AssociationDashboard.css";

interface IProps {
    players: Array<ProtectedPlayer>,
    //getAssociation: Function,
    sendRequest: Function,
    //uploadImage: Function,
    //association: Association,
    //updateAssociationData: Function,
    playerCount: number,
    getPlayers: Function
}

interface IState {
    licencedialogopen: boolean,
    dialogcontent: string,
    checkboxes: Array<boolean>,
    dialogopenedindex: number,
    //imagedialogopen: boolean,
    //selectedsports: Array<number>,
    pagenumber: number,
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

class AssociationDashboard extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        //this.props.getAssociation();
        this.state = {
            licencedialogopen: false,
            checkboxes: Array<boolean>(),
            dialogcontent: "",
            dialogopenedindex: 0,
            //imagedialogopen: false,
            //selectedsports: Array<number>(),
            pagenumber: 0,
        };
    }

    componentWillReceiveProps(newprops: any) {
        if (newprops.players !== this.props.players) {
            let initboxes = new Array<boolean>(newprops.players.length).fill(false);
            //let initsports = new Array<number>(newprops.players.length).fill(0);
            this.setState({ checkboxes: initboxes }); //, selectedsports: initsports });
        }
    }

    componentDidMount() {
        //this.props.getAssociation();
        this.props.getPlayers();
    }

    handleDialogOpen() {
        let content: string;
        let selectedplayers: number = 0;
        this.state.checkboxes.forEach(
            function (value) {
                if (value === true) selectedplayers++;
            }
        )
        content = selectedplayers + " fő";
        if (selectedplayers > 0) {
            this.setState({
                licencedialogopen: true,
                dialogcontent: content,
            })
        }
    }

    handleLicenceDialogClose() {
        this.setState({
            licencedialogopen: false,
            dialogcontent: "",
        })
    }

    checkAllBoxes(value: any) {
        let modified = this.state.checkboxes.slice();
        modified = modified.map(checkbox =>
            checkbox = value
        );
        this.setState({ checkboxes: modified });
    }

    handleCheckboxClick(i: number, value: any) {
        let modified = this.state.checkboxes.slice();
        modified[i] = value;
        this.setState({
            checkboxes: modified,
        })
    }

    /*handleImageUploadForPlayer(id: number) {
        this.setState({
            imagedialogopen: true,
            dialogopenedindex: id,
        })
    }

    handleImageDialogClose() {
        this.setState({
            imagedialogopen: false,
        })
    }*/

    createRequest() {
        let ids = new Array<number>(); //new Array<{ id: number, sport: number }>();
        this.state.checkboxes.forEach((box, i) => {
            if (box) ids.push(this.props.players[i].id);
            //if (box) ids.push({ id: this.props.players[i].id, sport: this.state.selectedsports[i] });
        });
        this.props.sendRequest(ids);
        this.handleLicenceDialogClose();

        let clearboxes = this.state.checkboxes.slice();
        clearboxes = clearboxes.map(checkbox =>
            checkbox = false
        );
        this.setState({ checkboxes: clearboxes });
    }

    /*handleSportSelectionChange = (sport: number, id: number) => {
        let listcopy = this.state.selectedsports.slice();
        listcopy[id] = sport;
        this.setState({ selectedsports: listcopy });
    }*/

    /*single action column
    <TableCell align="right">Igénylés</TableCell>*/

    TablePaginationActions(props: TablePaginationActionsProps) {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.ceil(count / rowsPerPage) - 1);
        };

        return (
            <div className="pagination">
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <FirstPage />
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <LastPage />
                </IconButton>
            </div>
        );
    }

    handleChangePage(value: number) {
        this.setState({ pagenumber: value });
        this.props.getPlayers(value + 1);
    }

    render() {
        return (
            <Container classes={{ root: "container" }} maxWidth="lg">
                {/*<AssociationData association={this.props.association} updateAssociationData={this.props.updateAssociationData} />*/}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox"><Checkbox
                                    onChange={e => this.checkAllBoxes(e.currentTarget.checked)}
                                    checked={
                                        this.state.checkboxes.filter((item) => item === true).length === this.props.players.length &&
                                        this.props.players.length !== 0
                                    }
                                    indeterminate={
                                        this.state.checkboxes.filter((item) => item === true).length !== this.props.players.length &&
                                        this.state.checkboxes.filter((item) => item === true).length !== 0
                                    }
                                />
                                </TableCell>
                                <TableCell>Azonosító</TableCell>
                                <TableCell>Név</TableCell>
                                {/*<TableCell>Sportorvosi érvényes</TableCell>*/}
                                {/*<TableCell>Sportág</TableCell>*/}
                                <TableCell>Versenyengedély érvényes</TableCell>
                                <TableCell>Közhiteles adatok</TableCell>
                                {/*<TableCell>Fénykép</TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <DashboardTableContent
                                checkboxes={this.state.checkboxes}
                                players={this.props.players}
                                handleCheckboxClick={(id: number, value: any) => this.handleCheckboxClick(id, value)}
                                //handleImageUploadForPlayer={(id: number) => this.handleImageUploadForPlayer(id)}
                                //selectedsports={this.state.selectedsports}
                                //handleSportSelectionChange={(sport: number, i: number) => this.handleSportSelectionChange(sport, i)}
                                key="associationdbtable" />
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Button
                                        onClick={() => this.handleDialogOpen()}
                                        variant="contained" size="small" color="primary">
                                        Kijelöltek igénylése
                                    </Button>
                                </TableCell>
                                <TablePagination
                                    rowsPerPageOptions={[12]}
                                    colSpan={3}
                                    count={this.props.playerCount}
                                    rowsPerPage={12}
                                    page={this.state.pagenumber}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={(e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => this.handleChangePage(newPage)}
                                    //onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={this.TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                    <NewLicenceDialog who={this.state.dialogcontent} open={this.state.licencedialogopen} handleAccept={() => this.createRequest()} handleClose={() => this.handleLicenceDialogClose()} />
                    {/*<ImgCropper dialogopen={this.state.imagedialogopen} handleClose={() => this.handleImageDialogClose()} uploadImage={(image: string) => { this.props.uploadImage(this.state.dialogopenedindex, image); this.handleImageDialogClose(); }} />*/}
                </TableContainer>
            </Container>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        players: state.AssociationAdminReducer.players,
        association: state.AssociationAdminReducer.association,
        playerCount: state.AssociationAdminReducer.playerCount,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        //getAssociation: () => dispatch(getAssociation()),
        getPlayers: (page: number) => dispatch(getPlayers(page)),
        sendRequest: (playerids: number[]) => dispatch(sendRequest(playerids, 0)),
        //uploadImage: (playerid: number, image: string) => dispatch(uploadImage(playerid, image)),
        //updateAssociationData: (association: Association) => dispatch(updateAssociationData(association)),
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
    (AssociationDashboard);