import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Address } from "../reducers/PlayerReducer";
import { ValidationError } from "./RegisterForm";
import settlementLookupTable from "../settlements.json"

interface IProps {
    handleChange: Function,
    address: Address,
    reportErrorState: Function,
    validationerrors: Map<string, ValidationError>
}

interface IState {
    settlements: string[],
}

class AddressForm extends React.Component<IProps, IState> {
    constructor(props: IProps){
        super(props);
        this.state = {
            settlements: [],
        }
    }

    validatePostCode = (name: string, value: string) => {
        if (value.length !== 4) {
            this.props.reportErrorState("postcodeerror", true, "Nem 4 jegyű szám.");
        }
        else {
            this.props.reportErrorState("postcodeerror", false, "");
            this.setState({ settlements: this.findSettlement(parseInt(value))});
        }
        this.props.handleChange(name, value);
    }

    validateStreetName = (identifier: string, value: string) => {
        if (value === "" || value === null) {
            this.props.reportErrorState("streetnameerror", true, "Mező kitöltése kötelező");
        }
        else if (/[^a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ -]/.test(value)) {
            this.props.reportErrorState("streetnameerror", true, "Nem megfelelő karakter");
        }
        else if (value.length > 30) {
            this.props.reportErrorState("streetnameerror", true, "Túl hosszú név");
        }
        else if (this.checkNameContainingSuffix(value)) {
            this.props.reportErrorState("streetnameerror", true, "A közterület neve tartalmazza annak típusát");
        }
        else {
            this.props.reportErrorState("streetnameerror", false, "");
        }
        this.props.handleChange(identifier, value);
    }

    checkNameContainingSuffix = (value: string): boolean => {
        let contains: boolean = false;
        suffixes.forEach(suffix => {
            if (value.includes(suffix)) contains = true;
        });
        let abbreviations: Array<string> = [" u", " krt", " ú", " sgt", " rkp"];

        abbreviations.forEach(suffix => {
            if (value.includes(suffix)) contains = true;
        });
        if (contains) return true;
        else return false;
    }

    validateSettlement = (identifier: string, value: string) => {
        if (value === "" || value === null) {
            this.props.reportErrorState("settlementerror", true, "Mező kitöltése kötelező");
        }
        /*else if (/[^a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ]/.test(value)) {
            this.props.reportErrorState("settlementerror", true, "Nem megfelelő karakter");
        }
        else if (value.length > 20) {
            this.props.reportErrorState("settlementerror", true, "Túl hosszú név");
        }*/
        else {
            this.props.reportErrorState("settlementerror", false, "");
        }
        this.props.handleChange(identifier, value);
    }

    validateStreetNumber = (identifier: string, value: string) => {
        if (value === "" || value === null) {
            this.props.reportErrorState("streetnumbererror", true, "Mező kitöltése kötelező");
        }
        else if (/[^a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0123456789 /.-]/.test(value)) {
            this.props.reportErrorState("streetnumbererror", true, "Nem megfelelő karakter");
        }
        else if (value.length > 8) {
            this.props.reportErrorState("streetnumbererror", true, "Túl hosszú érték");
        }
        else {
            this.props.reportErrorState("streetnumbererror", false, "");
        }
        this.props.handleChange(identifier, value);
    }

    validateAdditional = (identifier: string, value: string) => {
        if (/[^a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ0123456789 /,.-]/.test(value)) {
            this.props.reportErrorState("additionalerror", true, "Nem megfelelő karakter");
        }
        else if (value.length > 15) {
            this.props.reportErrorState("additionalerror", true, "Túl hosszú név");
        }
        else {
            this.props.reportErrorState("additionalerror", false, "");
        }
        this.props.handleChange(identifier, value);
    }

    findSettlement = (postcode: number) => {
        let settlements: string[] = [];
        if (postcode < 1000 || postcode >= 10000) return settlements;
        switch (true) {
            case (postcode >= 1000 && postcode < 2000): settlements = ["Budapest"]; break;
            case (postcode >= 4000 && postcode < 4050): settlements = ["Debrecen"]; break;
            case (postcode >= 6700 && postcode < 6750): settlements = ["Szeged"]; break;
            case (postcode >= 3500 && postcode < 3550): settlements = ["Miskolc"]; break;
            case (postcode >= 7600 && postcode < 7639): settlements = ["Pécs"]; break;
            case (postcode >= 9000 && postcode < 9050): settlements = ["Győr"]; break;
            default: settlements = this.settlementLookupByPostcode(postcode); break;
        }
        if(settlements.length === 1) this.props.handleChange("settlement", settlements[0])
        else this.props.handleChange("settlement", "");
        return settlements;
    }

    settlementLookupByPostcode = (postcode: number) => {
        return settlementLookupTable.filter((x: {postcode: number, settlement: string}) => x.postcode === postcode).map((y: {postcode: number, settlement: string}) =>  y.settlement);
    }

    settlementLookupByName = (name: string) => {
        return settlementLookupTable
            .filter((x: {postcode: number, settlement: string}) => x.settlement.toLowerCase().includes(name.toLowerCase()))
            .map((y: {postcode: number, settlement: string}) =>  y.settlement)
            .filter((value, index, array) => array.indexOf(value) === index);
    }

    render() {
        let postcodevalue: string;
        if (this.props.address.postcode <= 0) postcodevalue = "";
        else postcodevalue = this.props.address.postcode.toString();
        return (
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Irányítószám"
                            value={postcodevalue}
                            onChange={e => this.validatePostCode(e.currentTarget.name, e.currentTarget.value)}
                            fullWidth
                            type="number"
                            name="postcode"
                            color="primary"
                            error={this.props.validationerrors.get("postcodeerror")?.value}
                            helperText={this.props.validationerrors.get("postcodeerror")?.message} />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Autocomplete
                            autoComplete
                            includeInputInList
                            options={this.state.settlements}
                            value={this.props.address.settlement}
                            onChange={(e: any, v: any) => this.validateSettlement("settlement", v)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    onChange={e => {if(e.currentTarget.value) this.setState({settlements: this.settlementLookupByName(e.currentTarget.value)})}}
                                    fullWidth
                                    label="Település"
                                    color="primary"
                                    error={this.props.validationerrors.get("settlementerror")?.value}
                                    helperText={this.props.validationerrors.get("settlementerror")?.message} />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Közterület neve"
                            value={this.props.address.streetname}
                            onChange={e => this.validateStreetName(e.currentTarget.name, e.currentTarget.value)}
                            fullWidth
                            type="text"
                            name="streetname"
                            color="primary"
                            error={this.props.validationerrors.get("streetnameerror")?.value}
                            helperText={this.props.validationerrors.get("streetnameerror")?.message} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Autocomplete
                            autoComplete
                            includeInputInList
                            options={suffixes}
                            value={suffixes.find(x => x === this.props.address.streetsuffix)}
                            onChange={(e: any, v: any) => this.props.handleChange("streetsuffix", v)}
                            renderInput={(params) => <TextField {...params} onChange={e => this.props.handleChange("streetsuffix", e.currentTarget.value)} fullWidth label="Közterület típusa" color="primary" />} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField label="Házszám"
                            value={this.props.address.streetnumber}
                            onChange={e => this.validateStreetNumber(e.currentTarget.name, e.currentTarget.value)}
                            fullWidth
                            type="text"
                            name="streetnumber"
                            color="primary"
                            error={this.props.validationerrors.get("streetnumbererror")?.value}
                            helperText={this.props.validationerrors.get("streetnumbererror")?.message} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Emelet/Ajtó"
                            value={this.props.address.additional}
                            onChange={e => this.validateAdditional(e.currentTarget.name, e.currentTarget.value)}
                            fullWidth
                            type="text"
                            name="additional"
                            color="primary"
                            error={this.props.validationerrors.get("additionalerror")?.value}
                            helperText={this.props.validationerrors.get("additionalerror")?.message} />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

const suffixes = [
    "út", "utca", "árok", "átjáró", "bástya", "dűlő", "dűlősor", "dűlőút", "erdősor", "fasor", "forduló", "gát",
    "határsor", "határút", "játszótér", "kert", "körönd", "körtér", "körút", "köz", "lejáró", "lejtő",
    "lépcső", "liget", "mélyút", "orom", "ösvény", "park", "part", "rakpart", "sétaút", "sétány", "sor",
    "sugárút", "tér", "udvar", "üdülőpart"];

export default AddressForm;