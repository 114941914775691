import axios from "axios";
import { Dispatch } from "redux";
import { connectionUrl, requestOptions } from "../auth";
import { MatchResult } from "../reducers/MatchResultReducer";
import { MatchControllerActionTypes } from "./types/MatchControllerActionTypes";
import { NotificationTypes } from "./types/NotificationTypes";

export const startMatch = (id: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.get(`${connectionUrl}/match/startmatch/${id}`, requestOptions(getState()))
            .then(
                response => { 
                        let data = transformDate(response.data);
                        dispatch(success(data));
                    }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(matchResult: MatchResult) { return { type: MatchControllerActionTypes.GETMATCHRESULT, matchResult } }
    function failure(error: any) {return {type: NotificationTypes.FAILURE, message: error}}
}

export const endMatch = (id: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.get(`${connectionUrl}/match/endmatch/${id}`, requestOptions(getState()))
            .then(
                response => { 
                        let data = transformDate(response.data);
                        dispatch(success(data));
                    }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(matchResult: MatchResult) { return { type: MatchControllerActionTypes.GETMATCHRESULT, matchResult } }
    function failure(error: any) {return {type: NotificationTypes.FAILURE, message: error}}
}

export const addGoal = (matchId: number, scorer: number, assist: number, minute: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.post(`${connectionUrl}/match/goal`, {matchId, scorer, assist, minute}, requestOptions(getState()))
            .then(
                response => { 
                        let data = transformDate(response.data);
                        dispatch(success(data));
                    }
            )
            .catch(
                error => {dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))}
            );
    }
    function success(matchResult: MatchResult) { return { type: MatchControllerActionTypes.GETMATCHRESULT, matchResult } }
    function failure(error: any) {return {type: NotificationTypes.FAILURE, message: error}}
}

export const deleteGoal = (goalId: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.delete(`${connectionUrl}/match/goal/${goalId}`, requestOptions(getState()))
            .then(
                response => { 
                        let data = transformDate(response.data);
                        dispatch(success(data));
                    }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(matchResult: MatchResult) { return { type: MatchControllerActionTypes.GETMATCHRESULT, matchResult } }
    function failure(error: any) {return {type: NotificationTypes.FAILURE, message: error}}
}

export const addTimeout = (match: number, team: string, minute: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.post(`${connectionUrl}/match/timeout`, {match, team, minute}, requestOptions(getState()))
            .then(
                response => { 
                        let data = transformDate(response.data);
                        dispatch(success(data));
                    }
            )
            .catch(
                error => {dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))}
            );
    }
    function success(matchResult: MatchResult) { return { type: MatchControllerActionTypes.GETMATCHRESULT, matchResult } }
    function failure(error: any) {return {type: NotificationTypes.FAILURE, message: error}}
}

export const deleteTimeout = (timeoutId: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.delete(`${connectionUrl}/match/timeout/${timeoutId}`, requestOptions(getState()))
            .then(
                response => { 
                    let data = transformDate(response.data);
                    dispatch(success(data));
                }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(matchResult: MatchResult) { return { type: MatchControllerActionTypes.GETMATCHRESULT, matchResult } }
    function failure(error: any) {return {type: NotificationTypes.FAILURE, message: error}}
}

export const getControllerMatchResult = (matchId: number) => {
    
    return async (dispatch: Dispatch, getState: Function) => {
        await axios.get(`${connectionUrl}/match/${matchId}`)
            .then(
                response => { 
                        let data = transformDate(response.data);
                        dispatch(success(data));
                    }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(matchResult: MatchResult) { return { type: MatchControllerActionTypes.GETMATCHRESULT, matchResult } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

function transformDate(match: MatchResult) {
    match.date = new Date(match.date);
    match.startTime = new Date(match.startTime);
    match.endTime = new Date(match.endTime);
    return match;
}