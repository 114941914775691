import axios from "axios";
import { Dispatch } from "redux";
import { connectionUrl } from "../auth";
import { SinglePlayer } from "../reducers/SinglePlayerReducer";
import { SinglePlayerActionTypes } from "./types/SinglePlayerActionTypes";
import { direct } from "../Routes";
import { NotificationTypes } from "./types/NotificationTypes";
import { SingleAssociationActionTypes } from "./types/SingleAssociationActionTypes";
import { Player } from "../reducers/PlayerReducer";

export const getPlayer = (id: number) => {
    return async (dispatch: Dispatch) =>{
        await axios.get(`${connectionUrl}/player/${id}`)
            .then(
                response => {
                    dispatch(success(response.data));
                    direct("player");
                }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(player: SinglePlayer) {return { type: SinglePlayerActionTypes.GETPLAYER, player} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

/*function transformDate(jsonplayer: SinglePlayer) {
    let player: SinglePlayer = {
        ...jsonplayer,
        birthdate: new Date(jsonplayer.birthdate),
    }
    return player;
}*/

export const getPlayersFromAssociation = (id: number, page: number) => {
    return async (dispatch: Dispatch) =>{
        await axios.get(`${connectionUrl}/association/${id}/players?pagenumber=${page}`)
            .then(
                response => {
                    dispatch(success(response.data.players, response.data.pages));
                }
            )
            .catch(
                error => {dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))}
            );
    }
    function success(players: Array<Player>, pages: number) {return { type: SingleAssociationActionTypes.GETPLAYERSFROMASSOCIATION, players, pages} }
    function failure(error: string) { return { type: NotificationTypes.FAILURE, message: error } }
}