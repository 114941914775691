import { MatchResult } from "../../reducers/MatchResultReducer";

export type MatchResultActions = IGetMatchResult | IGetSeasonMatches | IGetMatchesToday;

export interface IGetMatchResult {
    type: MatchResultActionTypes.GETMATCHRESULT,
    matchResult: MatchResult,
}

export interface IGetSeasonMatches {
    type: MatchResultActionTypes.GETSEASONMATCHES,
    matches: Array<MatchResult>,
}

export interface IGetMatchesToday {
    type: MatchResultActionTypes.GETMATCHESTODAY,
    matches: Array<MatchResult>,
}

export enum MatchResultActionTypes{
    GETMATCHRESULT = 'GETMATCHRESULT',
    GETSEASONMATCHES = 'GETSEASONMATCHES',
    GETMATCHESTODAY = 'GETMATCHESTODAY'
}

export interface IMatchResultState {
    seasonMatches: Array<MatchResult>,
}

