import { Reducer } from "react";

export var backdropState = {
    open: false,
}

export const BackdropReducer: Reducer<{}, any> = (state= backdropState, action) => {
    switch (action.type) 
    {
        case "OPEN":
        {
            return {
                ...state,
                open: true,
            }   
        }
        case "CLOSE":
        {
            return {
                ...state,
                open: false,
            }   
        }
        default: return state;
    }
}