import axios from "axios";
import { Dispatch } from "redux";
import { connectionUrl } from "../auth";
import { AssociationsActionTypes } from "./types/AssociationActionTypes";
import { Association } from "../reducers/AssociationReducer";
import { SingleAssociation } from "../reducers/SingleAssociationReducer";
import { SingleAssociationActionTypes } from "./types/SingleAssociationActionTypes";
import { direct } from "../Routes";
//import { Player } from "../reducers/PlayerReducer";
import { NotificationTypes } from "./types/NotificationTypes";

export const getAllAssociation = () => {
    return async (dispatch: Dispatch) =>{
        await axios.get(`${connectionUrl}/association`)
            .then(
                response => {dispatch(success(response.data));}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(associations: Array<Association>) {return { type: AssociationsActionTypes.GETALL, associations} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const getAssociation = (id: number) => {
    return async (dispatch: Dispatch) =>{
        await axios.get(`${connectionUrl}/association/${id}`)
            .then(
                response => {
                    dispatch(success(response.data));
                    direct("association");
                }
            )
            .catch(
                error => {dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))}
            );
    }
    function success(singleassociation: SingleAssociation) {return { type: SingleAssociationActionTypes.GETASSOCIATION, singleassociation} }
    function failure(error: string) { return { type: NotificationTypes.FAILURE, message: error } }
}

/*function transformDate(jsonassociation: SingleAssociation) {
    let players = new Array<Player>();
    jsonassociation.players.forEach(
        function (jsonplayer){
            let player: Player = {
                ...jsonplayer,
                birthdate: new Date(jsonplayer.birthdate),
            }
            players.push(player);
        }
    );
    jsonassociation.players = players;
    return jsonassociation;
}*/