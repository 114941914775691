import * as React from "react";
import { connect } from "react-redux";
import { Paper, Container, Grid,/* ListItem, List,*/ Typography, Button, List, ListItem, Box } from "@material-ui/core";
import { getSubmissionData, getSubmittableMatches } from "../actions/MatchSubmissionActions";
import { MatchResult } from "../reducers/MatchResultReducer";
import "./SubmittableFutureMatchesPage.css"
import { direct } from "../Routes";
import { getMatchResult } from "../actions/MatchResultActions";

interface IProps {
    getSubmittableMatches: Function,
    submittableMatches: { homeMatches: Array<MatchResult>, awayMatches: Array<MatchResult> },
    getSubmissionData: Function,
    team: string,
    getMatchResult: Function,
}

class SubmittableFutureMatchesPage extends React.Component<IProps>{

    componentDidMount() {
        this.props.getSubmittableMatches(2);
    }

    render() {
        let homeMatches = this.props.submittableMatches?.homeMatches.filter(match => new Date(match.startTime).toDateString() !== new Date().toDateString()).map(match => {
            return <ListItem key={match.id} button onClick={() => this.props.getSubmissionData(match.id)} divider>
                <Grid container alignItems="center">
                    <Grid item xs={6}><Typography align="left">{match.awayTeam}</Typography></Grid>
                    <Grid item xs={6}><Typography align="right">{match.date.toLocaleDateString()}</Typography></Grid>
                </Grid>
            </ListItem>
        })
        let awayMatches = this.props.submittableMatches?.awayMatches.filter(match => new Date(match.startTime).toDateString() !== new Date().toDateString()).map(match => {
            return <ListItem key={match.id} button onClick={() => this.props.getSubmissionData(match.id)} divider>
                <Grid container alignItems="center">
                    <Grid item xs={6}><Typography align="left">{match.homeTeam}</Typography></Grid>
                    <Grid item xs={6}><Typography align="right">{match.date.toLocaleDateString()}</Typography></Grid>
                </Grid>
            </ListItem>
        })
        let matchesToday = this.props.submittableMatches?.homeMatches.filter(match => match.date.toDateString() === new Date().toDateString());
        return (
            <Container className="cont" maxWidth="lg">
                <Grid container justifyContent="space-evenly">
                    {matchesToday ? <Grid item xs={9}>
                        {matchesToday.map(matchToday =>
                            <Paper classes={{ root: "controllable-match-nav-tg" }} elevation={3}>
                                <Button variant="contained" classes={{ root: "match-control-nav-button-tg" }} onClick={() => {
                                    direct(`matchcontrol/${matchToday?.id}`);
                                }}>
                                    <Typography>Mérkőzésvezetés</Typography>
                                </Button>
                                <Typography variant="h6">{matchToday.awayTeam} - {matchToday.date.toLocaleDateString()} - {matchToday.date.toLocaleTimeString()}</Typography>
                            </Paper>)}
                    </Grid> : ""}

                    <Grid item xs={12}>
                        <Box bgcolor="#626262" borderRadius={3} p={2} m={2} color="white">
                            <Typography variant="body1" align="center">NEVEZÉSEK</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography align="center" variant="h5" gutterBottom>Hazai mérkőzések</Typography>
                        <Paper elevation={3}>
                            <List>{homeMatches}</List>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography align="center" variant="h5" gutterBottom>Idegenbeli mérkőzések</Typography>
                        <Paper elevation={3}>
                            <List>{awayMatches}</List>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        submittableMatches: state.AssociationAdminReducer.submittableMatches,
        team: state.AssociationAdminReducer.association.name,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        getSubmittableMatches: (competitionId: number) => dispatch(getSubmittableMatches(competitionId)),
        getSubmissionData: (matchId: number) => dispatch(getSubmissionData(matchId)),
        getMatchResult: (matchId: number) => dispatch(getMatchResult(matchId)),
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
    (SubmittableFutureMatchesPage);