import { Divider, Grid, List, ListItem, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import * as React from 'react';
import { Point } from '../reducers/MatchResultReducer';
import './MatchControlPage.css';

interface IProps {
    homeGoals: Array<Point>,
    awayGoals: Array<Point>,
    clickGoal: Function,
}

interface IState {
}

class GoalLists extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            
        }
    }

    public render() {
        let homeGoals = new Array<JSX.Element>();
        let awayGoals = new Array<JSX.Element>();
        this.props.homeGoals.forEach(goal => {
            homeGoals.push(<ListItem 
                key={goal.minute} 
                button 
                >
                    <Grid container>
                        <Grid item xs={11}><Typography variant="h6">{goal.scorer}</Typography></Grid>
                        <Grid item xs={1}><Typography>{goal.minute + "'"}</Typography></Grid>
                        <Grid item xs={11}><Typography>{goal.assist}</Typography></Grid>
                        <Grid item xs={1}><Delete onClick={() => this.props.clickGoal(goal.id)}/></Grid>
                    </Grid>
                </ListItem>);
            homeGoals.push(<Divider />)
        });
        this.props.awayGoals.forEach(goal => {
            awayGoals.push(<ListItem 
                key={goal.minute} 
                button 
                >
                    <Grid container>
                        <Grid item xs={11}><Typography variant="h6">{goal.scorer}</Typography></Grid>
                        <Grid item xs={1}><Typography>{goal.minute + "'"}</Typography></Grid>
                        <Grid item xs={11}><Typography>{goal.assist}</Typography></Grid>
                        <Grid item xs={1}><Delete onClick={() => this.props.clickGoal(goal.id)}/></Grid>
                    </Grid>
                </ListItem>)
        });
        return (
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <List>
                        <ListItem><b>Hazai pontok</b></ListItem>
                        {homeGoals}
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <List>
                        <ListItem><b>Vendég pontok</b></ListItem>
                        {awayGoals}
                    </List>
                </Grid>
            </Grid>
        );
    }
}

export default GoalLists;