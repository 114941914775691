import { SingleAssociation } from "../../reducers/SingleAssociationReducer";
import { Player } from "../../reducers/PlayerReducer";

export type SingleAssociationActions = IGetAssociation | IGetAllPlayersFromAssociation;

export interface IGetAssociation {
    type: SingleAssociationActionTypes.GETASSOCIATION,
    singleassociation: SingleAssociation,
}

export enum SingleAssociationActionTypes{
    GETASSOCIATION = 'GETASSOCIATION',
    GETPLAYERSFROMASSOCIATION = 'GETPLAYERSFROMASSOCIATION'
}

export interface ISingleAssociationState {
    singleassociation: SingleAssociation,
}

export interface IGetAllPlayersFromAssociation{
    type: SingleAssociationActionTypes.GETPLAYERSFROMASSOCIATION,
    players: Array<Player>,
    pages: number
}