import * as React from 'react';
import { Paper, Tabs, Tab, Grid, withWidth, isWidthUp, IconButton, Drawer, ListItem, ListItemIcon, List, ListItemText } from '@material-ui/core';
import './Navbar.css';
import { direct } from './Routes';
import { User } from './reducers/UserReducer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { Assignment, CardMembership, Dashboard, GpsFixed, PeopleAlt, PersonAdd, Sports } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';

interface IProps extends RouteComponentProps<any> {
    user: User,
    logout: any,
    width: Breakpoint,
}

interface IState {
    draweropen: boolean,
    dropDown: HTMLElement | null,
}

class Navbar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            draweropen: false,
            dropDown: null,
        }
    }

    checkPathname = () => {
        //this.props.location.pathname === "/login"? false : this.props.location.pathname
        let pathname = this.props.location.pathname;
        if (pathname === "/dashboard" || pathname === "/players" || pathname === "/associations" || pathname === "/register") return pathname;
        //if (pathname === "/dashboard" || pathname === "/results") return pathname; //átmeneti
        else return false;
    }

    public render() {
        let loggedintabs: Array<JSX.Element> = [];
        //let submissionMenuItem = false;
        let loginout;
        if (this.props.user !== null && this.props.user.email !== "") {
                loggedintabs = [<Tab key="dashboard" label="Kezelőfelület" value="/dashboard" onClick={() => direct("dashboard")} />];
            if (this.props.user.role === "AssociationAdmin") {
                //loggedintabs = [<Tab key="dashboard" label="Kezelőfelület" value="/dashboard" onClick={() => direct("dashboard")} />];
                loggedintabs.push(<Tab key="submittablematches" label="Mérkőzésvezérlés" value="/submittablematches" onClick={() => direct("submittablematches")} />); /*átmeneti*/
                //submissionMenuItem = true;
            }
            /*else if (this.props.user.role === "SystemAdmin")
                loggedintabs = [<Tab key="dashboard" label="Kezelőfelület" value="/dashboard" onClick={() => direct("dashboard")} />];
            else if (this.props.user.role === "ScoreKeeper")
                loggedintabs = [<Tab key="dashboard" label="Kezelőfelület" value="/dashboard" onClick={() => direct("dashboard")} />];*/
            loginout = <Tab key="logout" label="Kijelentkezés" onClick={() => this.props.logout()} />;
        }
        else loginout = <Tab key="login" classes={{ root: "login-button" }} value="/login" label="Bejelentkezés" onClick={() => direct("login")} />;

        return (
            <Paper>
                <Grid justifyContent="space-between" container>
                    {isWidthUp('md', this.props.width) ?
                        <Tabs
                            value={this.checkPathname()}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab key="associations" label="Egyesületek" value="/associations" onClick={() => direct("associations")} />
                            <Tab key="players" label="Játékosok" value="/players" onClick={() => direct("players")} />
                            <Tab key="register" label="Regisztráció" value="/register" onClick={() => direct("register")} />
                            {/*<Tab key="league" label="Bajnokság" value="/league" onClick={(e: React.MouseEvent<HTMLElement>) => this.setState({ dropDown: e.currentTarget })} />*/}
                            <Tab key="league" label="Bajnokság" value="/league" onClick={() => direct("leaguetable")} />
                            <Tab key="results" label="Eredmények" value="/results" onClick={() => direct("results")} /> {/*átmeneti*/}
                            {loggedintabs}

                        </Tabs> : <IconButton onClick={() => this.setState({ draweropen: true })}><MenuIcon /></IconButton>}
                    {loginout}
                    {/*<Menu
                        id="simple-menu"
                        anchorEl={this.state.dropDown}
                        keepMounted
                        open={Boolean(this.state.dropDown)}
                        onClose={() => this.setState({dropDown: null})}
                    >
                        <MenuItem onClick={() => {this.setState({dropDown: null}); direct("leaguetable")}}>Tabella</MenuItem>
                        <MenuItem onClick={() => {this.setState({dropDown: null}); direct("results")}}>Eredmények</MenuItem>
                        {submissionMenuItem ? <MenuItem onClick={() => {this.setState({dropDown: null}); direct("submittablematches")}}>Mérkőzésvezérlés</MenuItem> : ""}
                    </Menu>*/}
                </Grid>
                <Drawer open={this.state.draweropen} onClose={() => this.setState({ draweropen: false })}>
                    <List>
                        <ListItem button onClick={() => { this.setState({ draweropen: false }); direct("associations") }}>
                            <ListItemIcon><CardMembership /></ListItemIcon>
                            <ListItemText>Egyesületek</ListItemText>
                        </ListItem>
                        <ListItem button onClick={() => { this.setState({ draweropen: false }); direct("players") }}>
                            <ListItemIcon><PeopleAlt /></ListItemIcon>
                            <ListItemText>Játékosok</ListItemText>
                        </ListItem>
                        <ListItem button onClick={() => { this.setState({ draweropen: false }); direct("register") }}>
                            <ListItemIcon><PersonAdd /></ListItemIcon>
                            <ListItemText>Regisztráció</ListItemText>
                        </ListItem>
                        {<ListItem button onClick={() => { this.setState({ draweropen: false }); direct("leaguetable") }}>
                            <ListItemIcon><Assignment/></ListItemIcon>
                            <ListItemText>Tabella</ListItemText>
                        </ListItem>}
                        <ListItem button onClick={() => { this.setState({ draweropen: false }); direct("results") }}>
                            <ListItemIcon><Sports/></ListItemIcon>
                            <ListItemText>Eredmények</ListItemText>
                        </ListItem>
                        {(this.props.user !== null && this.props.user.email !== "") ?
                            <ListItem button onClick={() => { this.setState({ draweropen: false }); direct("dashboard") }}>
                                <ListItemIcon><Dashboard /></ListItemIcon>
                                <ListItemText>Kezelőfelület</ListItemText>
                            </ListItem> : null}
                        {(this.props.user !== null && this.props.user.email !== "") ?
                            <ListItem button onClick={() => { this.setState({ draweropen: false }); direct("submittablematches") }}>
                                <ListItemIcon><GpsFixed/></ListItemIcon>
                                <ListItemText>Mérkőzésvezérlés</ListItemText>
                            </ListItem> : null}
                    </List>
                </Drawer>
            </Paper>
        );
    }
}

export default withWidth()(withRouter(Navbar));