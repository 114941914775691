import { Association } from "../../reducers/AssociationReducer";

export type AssociationsActions = IGetAllAssociation;

export interface IGetAllAssociation {
    type: AssociationsActionTypes.GETALL,
    associations: Array<Association>,
}

export enum AssociationsActionTypes{
    GETALL = 'GETALL'
}

export interface IAssociationsState {
    associations: Array<Association>,
}