import * as React from "react";
import { connect } from "react-redux";
import CardHolder from "./CardHolder";
import { Player } from "../reducers/PlayerReducer";
import { findPlayers, getAllPlayer } from "../actions/PlayerActions";
import { getPlayer } from "../actions/SingleAssociationActions";

interface IProps{
    players: Array<Player>,
    getAllPlayer: Function,
    getPlayer: Function,
    findPlayers: Function,
    pages: number,
}

class PlayersPage extends React.Component<IProps>{

    componentDidMount()
    {
        this.props.getAllPlayer();
    }

    render(){
        return(
            <div><CardHolder {...this.props}/></div>
        );
    }
}

function mapStateToProps(state: any){
    return{
        players: state.PlayersReducer.players,
        pages: state.PlayersReducer.pages,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        getAllPlayer: () => dispatch(getAllPlayer()),
        getPlayer: (id: number) => dispatch(getPlayer(id)),
        findPlayers: (pagenumber: number, searchexpression: string) => dispatch(findPlayers(pagenumber, searchexpression)),
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
(PlayersPage);