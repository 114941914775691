import * as React from "react";
import { connect } from "react-redux";
import { Paper, Container } from "@material-ui/core";
import Details from "./Details";
import { SinglePlayer } from "../reducers/SinglePlayerReducer";
import { direct } from "../Routes";

interface IProps{
    player: SinglePlayer,
    //uploadImage: Function,
    //sendRequest: Function,
}

class SinglePlayerPage extends React.Component<IProps>{

    componentDidMount() {
        if(this.props.player.id === 0) direct("players");
    }
    
    render(){
        return(
            <Container className="cont" maxWidth="lg">
                <Paper elevation={3}>
                    <Details 
                        {...this.props}/>
                </Paper>
            </Container>
        );
    }
}

function mapStateToProps(state: any){
    return{
        player: state.SinglePlayerReducer.singleplayer,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
(SinglePlayerPage);