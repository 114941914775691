import { Reducer } from "react";
import { IAssociationAdminState, AssociationAdminActions, AssociationAdminActionTypes } from "../actions/types/AssociationAdminActionTypes";
import { MatchResult, PlayerAppearance } from "./MatchResultReducer";
import { Player } from "./PlayerReducer";
import { SingleAssociation } from "./SingleAssociationReducer";

export class ProtectedPlayer {
    id: number = 0;
    firstname: string = "";
    lastname: string = "";
    birthdate: Date = new Date();
    birthplace: string = "";
    mothersname: string = "";
    sportsmedicallicense: Date = new Date();
    licences: Array<Licence> = new Array<Licence>();
    imageURL: string = "";
    confirmedIdentity: boolean = false;
}

export class Licence {
    id: number = 0;
    sport: number = 0;
    validity: Date = new Date();
}

export function getSportName(sport: number) {
    switch (sport) {
        case 0: return "Ultimate";
        case 1: return "Disc-golf";
        case 2: return "Freestyle";
        default: return "";
    }
}

export var associationAdminState = {
    players: new Array<ProtectedPlayer>(),
    playerCount: 0,
    association: new SingleAssociation(),
    submittableMatches: { homeMatches: new Array<MatchResult>(), awayMatches: new Array<MatchResult>() },
    submittableMatch: { players: new Array<Player>(), submittedPlayers: new Array<PlayerAppearance>(), matchId: 0 },
}

export const AssociationAdminReducer: Reducer<IAssociationAdminState, AssociationAdminActions> = (state = associationAdminState, action) => {
    switch (action.type) {
        case AssociationAdminActionTypes.GETASSOCIATION:
            {
                return {
                    ...state,
                    association: action.association,
                    players: action.players
                }
            }
        case AssociationAdminActionTypes.GETPLAYERS:
            {
                return {
                    ...state,
                    players: action.players,
                    playerCount: action.playerCount
                }
            }
        case AssociationAdminActionTypes.UPDATEDLICENCES:
            {
                let players = state.players.slice();
                players.forEach(player => {
                    if(action.playerids.includes(player.id)) {
                        player.licences.forEach(l => l.sport === 0 ? l.validity = new Date(2024, 11, 31) : {})
                    }
                });
                return{
                    ...state,
                    players: players
                }
            }
        case AssociationAdminActionTypes.UPLOAD:
            {
                let players = state.players.slice();
                players.forEach(function (player) {
                    if (player.id === action.playerid) player.imageURL = action.imageURL;
                })
                return {
                    ...state,
                    players: players,
                }
            }
        case AssociationAdminActionTypes.GETSUBMITTABLEMATCHES:
            {
                return {
                    ...state,
                    submittableMatches: action.matches,
                }
            }
        case AssociationAdminActionTypes.GETSUBMITTABLEMATCH:
            {
                return {
                    ...state,
                    submittableMatch: action.match,
                }
            }
        case AssociationAdminActionTypes.POSTSUBMITTABLEMATCH:
            {
                let newSubmittableMatch = {
                    ...state.submittableMatch,
                    submittedPlayers: state.submittableMatch.submittedPlayers.concat(action.submittedPlayers)
                };
                return {
                    ...state,
                    submittableMatch: newSubmittableMatch,
                }
            }
        case AssociationAdminActionTypes.REMOVESUBMITTABLEMATCH:
            {
                let newSubmittableMatch = {
                    ...state.submittableMatch,
                    submittedPlayers: state.submittableMatch.submittedPlayers.filter(item => !action.ids.includes(item.id))
                };
                return {
                    ...state,
                    submittableMatch: newSubmittableMatch,
                }
            }
        case AssociationAdminActionTypes.UPDATEJERSEYNUMBER:
            {
                let newSubmittableMatch = {
                    ...state.submittableMatch,
                    submittedPlayers: state.submittableMatch.submittedPlayers.map(item => {
                        if (item.id === action.id) item.jerseyNumber = action.jerseyNumber;
                        return item;
                    })
                };
                return {
                    ...state,
                    submittableMatch: newSubmittableMatch,
                }
            }
        default: return state;
    }
}