import { SinglePlayer } from "../../reducers/SinglePlayerReducer";

export type SinglePlayerActions = IGetPlayer;

export interface IGetPlayer {
    type: SinglePlayerActionTypes.GETPLAYER,
    player: SinglePlayer,
}

export enum SinglePlayerActionTypes{
    GETPLAYER = 'GETPLAYER'
}

export interface ISinglePlayerState {
    singleplayer: SinglePlayer,
}