import * as React from "react";
import { connect } from "react-redux";
import { Grid, ListItem, List, Card, CardHeader, Checkbox, Divider, ListItemIcon, ListItemText, Button, Container, Select, MenuItem } from "@material-ui/core";
import { PlayerAppearance } from "../reducers/MatchResultReducer";
import { Player } from "../reducers/PlayerReducer";
import "./MatchSubmissionPage.css"
import { deleteSubmissionData, postSubmissionData, updateSubmissionData } from "../actions/MatchSubmissionActions";

interface IProps {
    match: { players: Array<Player>, submittedPlayers: Array<PlayerAppearance>, matchId: number },
    addPlayers: Function,
    removePlayers: Function,
    updateSubmissionData: Function,
    getMatchResult: Function,
}

export interface PlayerDisplay {
    playerId: number,
    name: string,
    jerseyNumber: number,
    id: number,
}

interface IState {
    left: Array<PlayerDisplay>,
    right: Array<PlayerDisplay>,
    leftChecked: Array<boolean>,
    rightChecked: Array<boolean>,
}

class MatchSubmissionPage extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            left: this.getPlayers(),
            right: this.getSubmissions(),
            leftChecked: new Array<boolean>(this.props.match.players?.length - this.props.match.submittedPlayers?.length | 0).fill(false),
            rightChecked: new Array<boolean>(this.props.match.submittedPlayers?.length).fill(false),
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.match !== this.props.match) {
            this.setState({
                left: this.getPlayers(),
                right: this.getSubmissions(),
                leftChecked: new Array<boolean>(this.props.match.players?.length - this.props.match.submittedPlayers?.length).fill(false),
                rightChecked: new Array<boolean>(this.props.match.submittedPlayers?.length).fill(false),
            });
        }
    }

    getPlayers = () => {
        let players = new Array<PlayerDisplay>();
        this.props.match.players?.forEach(player => {
            if (!this.props.match.submittedPlayers.some(pa => pa.playerId === player.id)) players.push({ playerId: player.id, name: player.lastname + " " + player.firstname, jerseyNumber: 0, id: 0 });
        });
        return players;
    }

    getSubmissions = () => {
        let players = new Array<PlayerDisplay>();
        this.props.match.submittedPlayers?.forEach(player => {
            players.push({ playerId: player.playerId, name: player.name, jerseyNumber: player.jerseyNumber, id: player.id });
        });
        return players;
    }

    handleToggle = (index: number, left: boolean) => () => {
        if (left) {
            const newChecked = [...this.state.leftChecked];
            newChecked[index] = !newChecked[index];
            this.setState({ leftChecked: newChecked });
        }
        else {
            const newChecked = [...this.state.rightChecked];
            newChecked[index] = !newChecked[index];
            this.setState({ rightChecked: newChecked });
        }
    };

    numberOfChecked = (items: Array<boolean>) => items.filter((item) => item === true).length;

    handleToggleAll = (left: boolean) => () => {
        if (left) {
            if (this.state.leftChecked.every((item) => item))
                this.setState({ leftChecked: new Array<boolean>(this.state.leftChecked.length).fill(false) })
            else this.setState({ leftChecked: new Array<boolean>(this.state.leftChecked.length).fill(true) });
        }
        else {
            if (this.state.rightChecked.every((item) => item))
                this.setState({ rightChecked: new Array<boolean>(this.state.rightChecked.length).fill(false) })
            else this.setState({ rightChecked: new Array<boolean>(this.state.rightChecked.length).fill(true) });
        }
    };

    handleCheckedRight = () => {
        let newRight = new Array<PlayerDisplay>();
        this.state.left.forEach((item, index) => {
            if (this.state.leftChecked[index]) {
                newRight.push(item);
            }
        })

        this.props.addPlayers(this.props.match.matchId, newRight);
    };

    handleCheckedLeft = () => {
        let newLeft = new Array<PlayerDisplay>()
        this.state.right.forEach((item, index) => {
            if (this.state.rightChecked[index]) {
                newLeft.push(item);
            }
        })

        this.props.removePlayers(newLeft.map(item => item.id));
    };

    generateRange = (startnumber: number, endnumber: number) => {
        let items: Array<JSX.Element> = new Array<JSX.Element>();
        for (let i = startnumber; i <= endnumber; i++) {
            items.push(<MenuItem key={startnumber + "-" + endnumber + "-" + i} value={i}>{i}</MenuItem>);
        }
        return items;
    }

    handleJerseySelect = (jersey: number, index: number, left: boolean) => {
        if (left) {
            let newLeft = [...this.state.left];
            newLeft[index].jerseyNumber = jersey;
            this.setState({ left: newLeft });
        }
        else {
            let newRight = [...this.state.right];
            newRight[index].jerseyNumber = jersey;
            this.props.updateSubmissionData(newRight[index]);
        }
    }

    customList = (title: string, items: Array<PlayerDisplay>, left: boolean) => (
        <Card elevation={3}>
            <CardHeader
                avatar={
                    <Checkbox
                        onClick={this.handleToggleAll(left)}
                        checked={
                            this.numberOfChecked(left ? this.state.leftChecked : this.state.rightChecked) === (left ? this.state.left.length : this.state.right.length) &&
                            (left ? this.state.left.length : this.state.right.length) !== 0
                        }
                        indeterminate={
                            this.numberOfChecked(left ? this.state.leftChecked : this.state.rightChecked) !== items.length &&
                            this.numberOfChecked(left ? this.state.leftChecked : this.state.rightChecked) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${this.numberOfChecked(left ? this.state.leftChecked : this.state.rightChecked)}/${items.length} kijelölve`}
            />
            <Divider />
            <List
                dense
                component="div"
                role="list"
            >
                <ListItem
                    key="header"
                >
                    <ListItemIcon />
                    <ListItemText>
                        <Grid container justify="space-between">
                            <Grid item>Játékosszám</Grid>
                            <Grid item>Név</Grid>
                            <Grid item>Mezszám</Grid>
                        </Grid>
                    </ListItemText>
                </ListItem>
                <Divider />
                {items.map((value, index) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value.playerId}
                            role="listitem"
                            button
                            onClick={this.handleToggle(index, left)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={left ? this.state.leftChecked[index] : this.state.rightChecked[index]}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId}>
                                <Grid container justify="space-between">
                                    <Grid item>{value.playerId}</Grid>
                                    <Grid item>{value.name}</Grid>
                                    <Grid item>
                                        <Select
                                            value={value.jerseyNumber}
                                            onChange={(e: any) => this.handleJerseySelect(parseInt(e.target.value), index, left)}
                                            name="jerseyNumber"
                                            color="primary">
                                            {this.generateRange(0, 99)}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </ListItemText>
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    render() {
        return (
            <Container maxWidth="lg" classes={{ root: "container" }}>
                <Grid container spacing={2} justify="center" classes={{root: "grid-container"}}>
                    <Grid xs={12} sm={5} item>{this.customList('Nevezhető játékosok', this.state.left, true)}</Grid>
                    <Grid xs={12} sm={2} item>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={this.handleCheckedRight}
                                disabled={this.numberOfChecked(this.state.leftChecked) === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={this.handleCheckedLeft}
                                disabled={this.numberOfChecked(this.state.rightChecked) === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={5} item>{this.customList('Nevezett játékosok', this.state.right, false)}</Grid>
                </Grid>
            </Container>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        match: state.AssociationAdminReducer.submittableMatch,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        addPlayers: (matchId: number, players: Array<PlayerDisplay>) => dispatch(postSubmissionData(matchId, players)),
        removePlayers: (ids: Array<number>) => dispatch(deleteSubmissionData(ids)),
        updateSubmissionData: (player: PlayerDisplay) => dispatch(updateSubmissionData(player)),
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
    (MatchSubmissionPage);