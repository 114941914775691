import * as React from "react";
import { /*AppBar, Tabs, Tab,*/ Container, Paper, /*Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Avatar,*/ Grid, List, ListItem, Typography, FormControl, InputLabel, MenuItem, Select, Button } from "@material-ui/core";
//import { getRequests, changeRequestState, getSimilarPlayers } from "../actions/SystemAdminActions";
import { connect } from "react-redux";
//import DashboardTableContent from "./DashboardTableContent";
//import { Request, Similarity } from "../reducers/SystemAdminReducer";
//import EvaluationDialog from "./EvaluationDialog";
import "./LicencesDashboard.css";
import SendPasswordResetEmail from "../ForgotPasswordPage/SendPasswordResetEmail";
import { sendForgottenPasswordEmail } from "../actions/UserActions"
import { MatchResult } from "../reducers/MatchResultReducer";
import { getTodaysMatches } from "../actions/MatchResultActions";
import { changeMatchData } from "../actions/SystemAdminActions";

interface IState {
    //value: number,
    //dialogopen: boolean,
    //selectedrequestid: number,
    //filteredrequests: Array<Request>,
    home: number,
    away: number,
}

interface IProps {
    //requests: Array<Request>,
    //getRequests: Function,
    //changeRequestState: Function,
    //getSimilarity: Function,
    //similarity: Similarity,
    sendPasswordResetEmail: Function,
    getMatches: Function,
    matches: Array<MatchResult>,
    changeMatchData: Function,
}

class LicencesDashboard extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            //value: 1,
            //dialogopen: false,
            //selectedrequestid: 0,
            //filteredrequests: new Array<Request>(),
            home: 0,
            away: 0,
        }
    }

    componentDidMount() {
        //this.props.getRequests(1);
        this.props.getMatches();
    }

    /*componentWillReceiveProps(newprops: any) {
        if(this.props.requests.length === 0)
        {
            this.setState({filteredrequests: newprops.requests});
        }
        else this.filterRequests(this.state.value);
    }*/

    /*handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            value: newValue,
        })
        this.props.getRequests(newValue);
    };*/

    /*handleDialogOpen(id: number) {
        this.setState({
            dialogopen: true,
            selectedrequestid: id,
        })
    }*/

    /*changeRequestState(state: number, reason: string) {
        this.setState({
            dialogopen: false
        });
        this.props.changeRequestState(state, this.state.selectedrequestid, reason);
    }*/

    /*handleDialogClose() {
        this.setState({
            dialogopen: false
        })
    }*/

    render() {
        console.log(this.props.matches)
        return (
            <Container classes={{ root: "container" }} maxWidth="lg">
                <Paper>
                    <List>{this.props.matches?.filter(m => m.awayTeam === null && m.homeTeam === null).map(match => {
                        return <ListItem key={match.id} divider>
                            <Grid container alignItems="center">
                                <Grid item xs={9} sm={4}>
                                    <FormControl variant="outlined">
                                        <InputLabel id="demo-simple-select-outlined-label">Team</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.home}
                                            onChange={e => this.setState({ home: e.target.value as number })}
                                            label="Age"
                                        >
                                            <MenuItem value={14}>MUFA</MenuItem>
                                            <MenuItem value={15}>UpFly</MenuItem>
                                            <MenuItem value={16}>Lionz</MenuItem>
                                            <MenuItem value={17}>OUT</MenuItem>
                                            <MenuItem value={18}>Heroes</MenuItem>
                                            <MenuItem value={19}>Sportsziget</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Grid item xs={12}><Typography align="center">{match.homeScore} : {match.awayScore}</Typography></Grid>
                                    <Grid item xs={12}><Typography align="center" display="block">{new Date(match.date).toLocaleTimeString()}</Typography></Grid>
                                </Grid>
                                <Grid item xs={9} sm={4}>
                                    <FormControl variant="outlined">
                                        <InputLabel id="demo-simple-select-outlined-label">Team</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.away}
                                            onChange={e => this.setState({ away: e.target.value as number })}
                                            label="Age"
                                        >
                                            <MenuItem value={14}>MUFA</MenuItem>
                                            <MenuItem value={15}>UpFly</MenuItem>
                                            <MenuItem value={16}>Lionz</MenuItem>
                                            <MenuItem value={17}>OUT</MenuItem>
                                            <MenuItem value={18}>Heroes</MenuItem>
                                            <MenuItem value={19}>Sportsziget</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5} sm={2}><Button variant="outlined" color="primary" onClick={() => this.props.changeMatchData(match.id, this.state.away, this.state.home)}>Set</Button></Grid>
                            </Grid>
                        </ListItem>
                    })}</List>
                </Paper>
                {/*<Paper>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="Regisztrációs kérelem" value={1} />
                            <Tab label="Versenyengedély kérelem" value={0} />
                        </Tabs>
                    </AppBar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Azonosító</TableCell>
                                    {this.state.value === 1 ? <TableCell>Egyezés</TableCell> : <TableCell>Sportág</TableCell>}
                                    <TableCell>Igénylés ideje</TableCell>
                                    <TableCell>Igénylő egyesület</TableCell>
                                    <TableCell>Játékos</TableCell>
                                    {this.state.value === 1 ? "" : <TableCell>Díj</TableCell>}
                                    <TableCell align="right">Elbírálás</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <DashboardTableContent status={this.state.value} handleDialogOpen={(id: number) => this.handleDialogOpen(id)} getSimilarity={this.props.getSimilarity} requests={this.props.requests} key="licencesdbtable" similarity={this.props.similarity} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <EvaluationDialog open={this.state.dialogopen} changeRequestState={(state: number, reason: string) => this.changeRequestState(state, reason)} handleClose={() => this.handleDialogClose()} />
                </Paper>*/}
                <Paper>
                    <SendPasswordResetEmail sendPasswordResetEmail={this.props.sendPasswordResetEmail} />
                </Paper>
            </Container>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        //requests: state.SystemAdminReducer.requests,
        //similarity: state.SystemAdminReducer.similarity,
        matches: state.MatchResultReducer.todaysMatches,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        //getRequests: (type: number) => dispatch(getRequests(type)),
        //changeRequestState: (state: number, id: number, reason: string) => dispatch(changeRequestState(state, id, reason)),
        //getSimilarity: (id: number) => dispatch(getSimilarPlayers(id)),
        sendPasswordResetEmail: (email: string) => dispatch(sendForgottenPasswordEmail(email)),
        getMatches: () => dispatch(getTodaysMatches()),
        changeMatchData: (matchId: number, awayTeam: number, homeTeam: number) => dispatch(changeMatchData(matchId, awayTeam, homeTeam))
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
    (LicencesDashboard);

