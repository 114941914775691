import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, IconButton, Grid, Typography } from "@material-ui/core";

interface IProps {
    open: boolean,
    handleClose: Function,
    addTimeout: Function,
    minute: number,
    homeTeam: string,
    awayTeam: string,
}

interface IState {
    team: string,
    minute: number,
}

class AddTimeoutDialog extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            team: "",
            minute: 0,
        }
    }

    handleChange = (event: any) => {
        if (event.target.value >= 0 && event.target.value <= 150 && event.target.value % 1 === 0) {
            this.setState({
                minute: parseInt(event.target.value)
            });
        }
    }

    handleClose = () => {
        this.props.handleClose();
        this.setState({ minute: 0, team: "" });
    }

    render() {
        return (
            <Dialog fullWidth onClose={() => this.handleClose()} open={this.props.open} >
                <DialogTitle>
                    <Grid container justify="space-evenly">
                        <Grid item>Időkérés hozzáadása</Grid>
                        <Grid item><IconButton onClick={() => this.handleClose()}>X</IconButton></Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={5} />
                            <Grid item xs={2}>
                                <TextField
                                    classes={{ root: "lineup-list" }}
                                    autoFocus
                                    name="minute"
                                    label="Perc"
                                    type="number"
                                    variant="standard"
                                    color="primary"
                                    value={this.state.minute === 0 ? this.props.minute : this.state.minute}
                                    onInput={(event) => this.handleChange(event)}
                                />
                            </Grid>
                            <Grid item xs={5} />
                            <Grid item xs={6} classes={{ root: "timeout-chooser" }}>
                                <Button
                                    variant={this.state.team === this.props.homeTeam ? "contained" : "outlined"}
                                    color={this.state.team === this.props.homeTeam ? "primary" : "default"}
                                    onClick={() => this.setState({ team: this.props.homeTeam })}
                                >
                                    <Typography>Hazai</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6} classes={{ root: "timeout-chooser" }}>
                                <Button
                                    variant={this.state.team === this.props.awayTeam ? "contained" : "outlined"}
                                    color={this.state.team === this.props.awayTeam ? "primary" : "default"}
                                    onClick={() => this.setState({ team: this.props.awayTeam })}
                                >
                                    <Typography>Vendég</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.handleClose()}
                        classes={{ root: "red" }}>
                        Mégsem
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.addTimeout(this.state.team, this.state.minute === 0 ? this.props.minute : this.state.minute);
                            this.handleClose()
                        }}
                        classes={{ root: "green" }} autoFocus>
                        Hozzáadás
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default AddTimeoutDialog;