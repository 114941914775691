import { Reducer } from "react";
import { IPlayersState, PlayersActions, PlayersActionTypes } from "../actions/types/PlayerActionTypes";
import { Association } from "./AssociationReducer";

export class Player{
    id: number = 0;
    firstname: string = "";
    lastname: string = "";
    birthdate: number = 0;
    imageURL: string = "";
    associationImageURL: string = "";
    association: Association = new Association();
}

export class Address{
    postcode: number = 0;
    settlement: string = "";
    streetsuffix: string = "";
    streetname: string = "";
    streetnumber: string = "";
    additional: string = "";
}

export class RegisteringPlayer{
    firstname: string ="";
    lastname: string = "";
    birthdate: string = "2000-01-01";
    birthplace: string = "";
    gender: string = "";
    mothersfirstname: string = "";
    motherslastname: string = "";
    licences: Array<number> = new Array<number>();
    address: Address = new Address();
    association: number = 0;
    emailaddress: string = "";
}

export var playersState = {
    players: new Array<Player>(),
    pages: 1,
}

export const PlayersReducer: Reducer<IPlayersState, PlayersActions> = (state= playersState, action) => {
    switch (action.type) 
    {
        case PlayersActionTypes.GETALL:
        {
            return {
                ...state,
                players: action.players,
                pages: action.pages,
            }   
        }
        default: return state;
    }
}
  