import { Reducer } from "redux";
import { LoginActions, userActions, IUserState } from "../actions/types/UserActionTypes";

export class User{
    email: string = "";
    token: string = localStorage.getItem("webapp_mrksz") || "";
    role: string = "";
}

const userInitialState: IUserState = {loggedinuser: new User(), error: ""};

export const UserReducer: Reducer<IUserState, LoginActions> = (state= userInitialState, action) => {
    switch(action.type)
    {
        case userActions.LOGIN_SUCCESS : {
            localStorage.setItem("webapp_mrksz", action.user.token);
            return{
                ...state,
                loggedinuser: action.user
            };
        }
        case userActions.LOGIN_FAILURE : {
            return{
                ...state,
                error: "Hibás felhasználónév vagy jelszó",
            };
        }
        case userActions.LOGOUT : {
            localStorage.removeItem("webapp_mrksz");
            return{
                ...state,
                loggedinuser: new User()
            }
        }
        case userActions.HIDE_ERROR : {
            return{
                ...state,
                error: "",
            }
        }
        default : return state;
    }
}