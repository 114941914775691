import axios from "axios";
import { Dispatch } from "redux";
import { connectionUrl } from "../auth";
import { PlayerStat, Team } from "../reducers/CompetitionReducer";
import { MatchResult } from "../reducers/MatchResultReducer";
import { CompetitionActionTypes } from "./types/CompetitionActionTypes";
import { MatchResultActionTypes } from "./types/MatchResultActionTypes";
import { NotificationTypes } from "./types/NotificationTypes";

export const getLeagueTable = (competitionId: number) => {
    return async (dispatch: Dispatch) => {
        await axios.get(`${connectionUrl}/competition/${competitionId}/leaguetable`)
            .then(
                response => { dispatch(success(response.data)) }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(leagueTable: Array<Team>) { return { type: CompetitionActionTypes.GETLEAGUETABLE, leagueTable } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const getPlayerStat = (competitionId: number, type?: string) => {
    return async (dispatch: Dispatch) => {
        await axios.get(`${connectionUrl}/competition/${competitionId}/playerstats?type=${type || ""}`)
            .then(
                response => dispatch(success(response.data))
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltatás nem üzemel"))
            )
    }
    function success(playerStats: Array<PlayerStat>) { return { type: CompetitionActionTypes.GETPLAYERSTATS, playerStats } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const getCompetitionMatches = (competitionId: number, lastxmatches?: number) => {
    return async (dispatch: Dispatch) => {
        let lastXMatchString = "?lastxmatches=" + lastxmatches;
        await axios.get(`${connectionUrl}/competition/${competitionId}/results${lastXMatchString}`)
            .then(
                response => { dispatch(success(response.data)); }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(matches: Array<MatchResult>) { return { type: MatchResultActionTypes.GETSEASONMATCHES, matches } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}