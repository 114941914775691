import axios from "axios";
import { User } from "../reducers/UserReducer";
import { backdropActions, userActions } from "./types/UserActionTypes";
import { Dispatch } from "redux";
import { direct } from "../Routes";
import { connectionUrl, requestOptions } from "../auth";
import { NotificationTypes } from "./types/NotificationTypes";

export const login = (email: string, password: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: backdropActions.OPEN });
        await axios.post(`${connectionUrl}/user/signin`, { email, password })
            .then(
                response => {
                    dispatch(success(response.data));
                    dispatch({ type: backdropActions.CLOSE });
                    direct("dashboard");
                })
            .catch(
                error => {
                    dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"));
                    dispatch({ type: backdropActions.CLOSE });
                }
            );
    };

    function success(user: User) { return { type: userActions.LOGIN_SUCCESS, user } }
    function failure(error: any) { return { type: userActions.LOGIN_FAILURE, message: error } }
}

export const authenticate = () => {
    return async (dispatch: Dispatch, getState: Function) => {
        await axios.get(`${connectionUrl}/user/auth`, requestOptions(getState()))
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(
                error => {
                    dispatch(failure(error.response?.data || "Sikertelen azonosítás"));
                }
            );
    };

    function success(user: User) { return { type: userActions.LOGIN_SUCCESS, user } }
    function failure(error: any) { return { type: userActions.LOGOUT, message: error } }
}

export const logout = () => {
    return async (dispatch: Dispatch, getState: Function) => {
        await axios.get(`${connectionUrl}/user/signout`, requestOptions(getState()))
            .then(
                response => {
                    dispatch(success(response.data));
                    //direct("associations"); eredeti
                    direct(""); //átmeneti
                })
            .catch(
                error => {
                    dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"));
                }
            );
    }
    function success(message: any) { return { type: userActions.LOGOUT } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const sendForgottenPasswordEmail = (email: string) => {
    return async (dispatch: Dispatch, getState: Function) => {
        await axios.post(`${connectionUrl}/user/resetpassword`, { email }, requestOptions(getState()))
            .then(
                response => {
                    dispatch(success(response.data));
                })
            .catch(
                error => {
                    dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"));
                }
            );
    };
    function success(message: any) { return { type: NotificationTypes.SUCCESS, message: message } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const changePassword = (password: string, token: string) => {
    return async (dispatch: Dispatch, getState: Function) => {
        dispatch({ type: backdropActions.OPEN });
        await axios.post(`${connectionUrl}/user/changepassword`, { password, token }, requestOptions(getState()))
            .then(
                response => {
                    dispatch(success(response.data));
                    dispatch({ type: backdropActions.CLOSE });
                    direct("login");
                })
            .catch(
                error => {
                    dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"));
                    dispatch({ type: backdropActions.CLOSE });
                }
            );
    };
    function success(message: any) { return { type: NotificationTypes.SUCCESS, message: message } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}