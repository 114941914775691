import { Typography } from "@material-ui/core";
import * as React from "react";

const NotFoundPage: React.FC = () => {
    return (
        <div className="page-not-found">
            <Typography align="center" color="textSecondary" variant="h1">404</Typography>
            <Typography align="center" color="textSecondary" variant="h2">A keresett oldal nem található.</Typography>
        </div>
    );
};

export default NotFoundPage;