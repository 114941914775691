import * as React from "react";
import { connect } from "react-redux";
import CardHolder from "./CardHolder";
import { getAssociation, getAllAssociation } from "../actions/AssociationActions";
import { Association } from "../reducers/AssociationReducer";

interface IProps{
    associations: Array<Association>,
    getAssociation: Function,
    getAllAssociation: Function,
}

class AssociationsPage extends React.Component<IProps>{

    componentDidMount()
    {
        this.props.getAllAssociation();
    }

    render(){
    return(
        <div><CardHolder {...this.props}/></div>
    );
    }
}

function mapStateToProps(state: any){
    return{
        associations: state.AssociationsReducer.associations,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        getAssociation: (id: number) => dispatch(getAssociation(id)),
        getAllAssociation: () => dispatch(getAllAssociation()),
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
(AssociationsPage);