import { Reducer } from "react";
import { ISingleAssociationState, SingleAssociationActions, SingleAssociationActionTypes } from "../actions/types/SingleAssociationActionTypes";
import { Address, Player } from "./PlayerReducer";

export class SingleAssociation{
    id: number = 0;
    name: string = "";
    founded: number = 0;
    description: string = "";
    imageURL: string = "";
    webaddress: string = "";
    phonenumber: string = "";
    email: string = "";
    address: Address = new Address();
}

export var associationState = {
    singleassociation: new SingleAssociation(),
    playersFromAssociation: new Array<Player>(),
    playerPages: 0
}

export const SingleAssociationReducer: Reducer<ISingleAssociationState, SingleAssociationActions> = (state= associationState, action) => {
    switch (action.type) 
    {
        case SingleAssociationActionTypes.GETASSOCIATION:
        {
            return {
                ...state,
                singleassociation: action.singleassociation
            }   
        }
        case SingleAssociationActionTypes.GETPLAYERSFROMASSOCIATION:
        {
            return {
                ...state,
                playersFromAssociation: action.players,
                playerPages: action.pages
            }
        }
        default: return state;
    }
}
  