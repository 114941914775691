import { Color } from "@material-ui/lab";

export type Notification = IFailure | ISuccess | IWarning | IInfo | IDisappearNotification;

export interface IFailure {
    type: NotificationTypes.FAILURE,
    message: string,
}

export interface ISuccess {
    type: NotificationTypes.SUCCESS,
    message: string,
}

export interface IWarning {
    type: NotificationTypes.WARNING,
    message: string,
}

export interface IInfo {
    type: NotificationTypes.INFO,
    message: string,
}

export interface IDisappearNotification {
    type: NotificationTypes.DISAPPEAR,
}

export enum NotificationTypes{
    FAILURE = "FAILURE",
    SUCCESS = "SUCCESS",
    WARNING = "WARNING",
    INFO = "INFO",
    DISAPPEAR = "DISAPPEAR",
}

export interface INotificationState {
    message: string,
    severity: Color,
}