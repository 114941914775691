import { Button, Container, Grid, TextField } from '@material-ui/core';
import * as React from 'react';
import './ForgotPassword.css';

interface IState {
    email: string,
}

interface IProps {
    sendPasswordResetEmail: Function,
}

class SendPasswordResetEmail extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            email: '',
        };
    }

    handleChange = (email: string) => {
        this.setState({
            email: email
        });
    }

    handleSend = () => {
        this.props.sendPasswordResetEmail(this.state.email);
    }

    render(){
        return(
            <Container classes={{root: "margin"}}>
                <Grid container direction="column" alignItems="center">
                        <TextField
                            autoFocus
                            classes={{ root: "field" }}
                            name="email"
                            label="E-mail"
                            type="email"
                            variant="outlined"
                            color="primary"
                            value={this.state.email}
                            onInput={(event: any) => this.handleChange(event.target.value)}
                        />
                        <Button onClick={this.handleSend} variant="outlined" color="primary">Jelszóváltoztató token generálás</Button>
                    </Grid>
            </Container>
        );
    }
}

export default SendPasswordResetEmail;