import * as React from "react";
import { connect } from "react-redux";
import { Paper, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Grid, List, ListItem, Typography, Box, Tabs, Tab } from "@material-ui/core";
import { getCompetitionMatches } from "../actions/CompetitionActions";
import { PlayerStat, Team } from "../reducers/CompetitionReducer";
import { MatchResult } from "../reducers/MatchResultReducer";
import { direct } from "../Routes";
import { getLeagueTable, getPlayerStat } from "../actions/CompetitionActions";
import "./LeagueTablePage.css";

interface IProps {
    getLeagueTable: Function,
    leagueTable: Array<Team>,
    seasonMatches: Array<MatchResult>,
    getCompetitionMatches: Function,
    getPlayerStats: Function,
    playerStats: Array<PlayerStat>,
}

interface IState {
    individualTabVariant: number,
}

class LeagueTablePage extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = {
            individualTabVariant: 0,
        }
    }

    componentDidMount() {
        this.props.getLeagueTable(2);
        this.props.getCompetitionMatches(2);
        this.props.getPlayerStats(2, "score");
    }

    handleTabChange(value: number) {
        switch (value) {
            case 0: this.props.getPlayerStats(2, "score"); break;
            case 1: this.props.getPlayerStats(2, "assist"); break;
            case 2: this.props.getPlayerStats(2); break;
            default: this.props.getPlayerStats(2, "score"); break;
        }

        this.setState({ individualTabVariant: value });
    }

    render() {
        let rank = 0;
        var leagueRows: JSX.Element[] = [];
        if (this.props.leagueTable !== undefined && this.props.leagueTable.length !== 0) {
            leagueRows = this.props.leagueTable.map(team => {
                rank++;
                return <TableRow key={rank} hover={true} classes={{ root: "nonclickable" }}>
                    <TableCell align="center">{rank}</TableCell>
                    <TableCell align="center" padding="checkbox"><Avatar src={team.picture} /></TableCell>
                    <TableCell align="left">{team.name}</TableCell>
                    <TableCell align="center">{team.played}</TableCell>
                    <TableCell align="center"><b>{team.win}</b></TableCell>
                    <TableCell align="center">{team.loss}</TableCell>
                    <TableCell align="center">{team.pointsFor}</TableCell>
                    <TableCell align="center">{team.pointsAgainst}</TableCell>
                    <TableCell align="center">{team.pointsFor - team.pointsAgainst}</TableCell>
                </TableRow>
            })
        }

        let matchRows = this.props.seasonMatches.map(match => {
            return <ListItem key={match.id} button onClick={() => direct("match/" + match.id)} divider>
                <Grid container alignItems="center">
                    <Grid item xs={3} sm={2}><Avatar classes={{ root: "leftside-item" }} src={match.homePicture} /></Grid>
                    <Grid item xs={9} sm={3}><Typography align="left" variant="body2">{match.homeTeam}</Typography></Grid>
                    <Grid item xs={12} sm={2} container>
                        <Grid item xs={12}><Typography align="center" variant="body1"><b>{match.homeScore} : {match.awayScore}</b></Typography></Grid>
                        <Grid item xs={12}><Typography align="center" variant="body2" display="block">{this.getMatchDateAndTime(new Date(match.date))}</Typography></Grid>
                    </Grid>
                    <Grid item xs={9} sm={3}><Typography align="right" variant="body2">{match.awayTeam}</Typography></Grid>
                    <Grid item xs={3} sm={2}><Avatar classes={{ root: "rightside-item" }} src={match.awayPicture} /></Grid>
                </Grid>
            </ListItem>
        });

        rank = 0;
        let statRows = this.props.playerStats.map(stat => {
            rank++;
            return <TableRow key={rank} hover={true} classes={{ root: "nonclickable" }}>
                <TableCell align="center">{rank}</TableCell>
                <TableCell align="left">{stat.name}</TableCell>
                <TableCell align="center">{stat.played}</TableCell>
                <TableCell align="center">{this.state.individualTabVariant === 0 ? <b>{stat.points}</b> : stat.points}</TableCell>
                <TableCell align="center">{this.state.individualTabVariant === 1 ? <b>{stat.assists}</b> : stat.assists}</TableCell>
            </TableRow>
        })

        return (
            <Container className="cont" maxWidth="lg">
                <Grid container spacing={3} justifyContent="center">
                    <Grid item container lg={8} spacing={3}>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <Box bgcolor="#626262" borderRadius={3} p={2} color="white">
                                    <Typography variant="body1" align="center">TABELLA</Typography>
                                </Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow classes={{ root: "nonclickable" }}>
                                                <TableCell align="center">#</TableCell>
                                                <TableCell align="left" colSpan={2} padding="checkbox">Csapat</TableCell>
                                                <TableCell align="center">M</TableCell>
                                                <TableCell align="center"><b>Gy</b></TableCell>
                                                <TableCell align="center">V</TableCell>
                                                <TableCell align="center">SzP</TableCell>
                                                <TableCell align="center">KP</TableCell>
                                                <TableCell align="center">PK</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {leagueRows}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                {/*<Box bgcolor="#525252" borderRadius={3} p={2} color="white">
                                    <Typography variant="h5" align="center">Egyéni pont</Typography>
                                </Box>*/}
                                <Box bgcolor="#626262" borderRadius={3} color="white">
                                    <Tabs value={this.state.individualTabVariant} onChange={(e, v: number) => this.handleTabChange(v)} variant="fullWidth" centered>
                                        <Tab label="Pont" />
                                        <Tab label="Assziszt" />
                                        <Tab label="Kanadai" />
                                    </Tabs>
                                </Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow classes={{ root: "nonclickable" }}>
                                                <TableCell align="center">#</TableCell>
                                                <TableCell align="left">Név</TableCell>
                                                <TableCell align="center">M</TableCell>
                                                <TableCell align="center">{this.state.individualTabVariant === 0 ? <b>P</b> : "P"}</TableCell>
                                                <TableCell align="center">{this.state.individualTabVariant === 1 ? <b>A</b> : "A"}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {statRows}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Paper elevation={3}>
                            <Box bgcolor="#626262" borderRadius={3} p={2} color="white">
                                <Typography variant="body1" align="center">MÉRKŐZÉSEK</Typography>
                            </Box>
                            <List>{matchRows}</List>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    }

    getMatchDateAndTime = (date: Date) => {
        return this.addZeroToSingleDigitTime(date.getMonth() + 1) + "."
            + this.addZeroToSingleDigitTime(date.getDate()) + ". "
            + this.addZeroToSingleDigitTime(date.getHours()) + ":"
            + this.addZeroToSingleDigitTime(date.getMinutes());
    }

    addZeroToSingleDigitTime = (x: number) => {
        return x < 10 ? "0" + x : x;
    }
}

function mapStateToProps(state: any) {
    return {
        leagueTable: state.CompetitionReducer.leagueTable,
        seasonMatches: state.MatchResultReducer.seasonMatches,
        playerStats: state.CompetitionReducer.playerStats,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        getLeagueTable: (competitionId: number) => dispatch(getLeagueTable(competitionId)),
        getCompetitionMatches: (competitionId: number) => dispatch(getCompetitionMatches(competitionId, 14)),
        getPlayerStats: (competitionId: number, type: string) => dispatch(getPlayerStat(competitionId, type))
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
    (LeagueTablePage);