import { Reducer } from "react";
import { IMatchControllerState, MatchControllerActions, MatchControllerActionTypes } from "../actions/types/MatchControllerActionTypes";
import { MatchResult } from "./MatchResultReducer";

export var matchState = {
    match: new MatchResult(),
}

export const MatchControlReducer: Reducer<IMatchControllerState, MatchControllerActions> = (state = matchState, action) => {
    switch (action.type) {
        case MatchControllerActionTypes.GETMATCHRESULT:
            {
                return {
                    ...state,
                    match: action.matchResult,
                }
            }
        default: return state;
    }
}
