import * as React from 'react';
import { Container, Grid } from '@material-ui/core';
import ImgCard from './ImgCard';
import { Association } from '../reducers/AssociationReducer';
import { Skeleton } from '@material-ui/lab';

interface IProps{
    associations: Array<Association>,
    getAssociation: Function
}

class CardHolder extends React.Component<IProps>{

    public render() {
        var cardlist = [];
        if(this.props.associations !== undefined && this.props.associations.length > 0)
        {
            for(let i = 0; i < this.props.associations.length; i++)
            {
                let card = <ImgCard key={"card"+i} association = {this.props.associations[i]} {...this.props}/>
                cardlist.push(card);
            }
        }
        else for(let i = 0; i < 6; i++){ cardlist.push(<Skeleton key={"associationskeleton" + i}> <ImgCard key={"card"+i} association = {new Association()} getAssociation={() => {}}/></Skeleton>);}
        return (
            <Container>
                <Grid container justifyContent="space-evenly">{cardlist}</Grid>
            </Container>
        );
    }
}

export default CardHolder;