import * as React from 'react';
import { Card, CardActionArea, CardHeader, Avatar, CardMedia } from '@material-ui/core';
import "./ImgCard.css"
import { Player } from '../reducers/PlayerReducer';
import { PersonOutline } from '@material-ui/icons';

interface IProps{
  player: Player,
  getPlayer: Function
}

class ImgCard extends React.Component<IProps> {

  handleclick() {
    this.props.getPlayer(this.props.player.id);
  }

  public render() {
    //console.log(this.props.player.association, this.props.player.associationImageURL);
    //<Image cloudName="dzonzfwfn" publicId="samples/ecommerce/shoes.png" width="300" />
    let initials = this.props.player.lastname.slice(0,1).toUpperCase() + this.props.player.firstname.slice(0,1).toUpperCase();
    let nopicture = this.props.player.imageURL? "" : <PersonOutline classes={{root: "person-icon"}} color="disabled"/>;
    return (
      <Card classes={{root: "card"}} elevation={4} onClick={() => this.handleclick()}>
        <CardHeader
          avatar={<Avatar src={this.props.player.association?.imageURL} classes={this.props.player.association?.imageURL? {} : {root: "card-avatar"}}>{initials}</Avatar>}
          title={this.props.player.lastname + " " + this.props.player.firstname}
          //subheader=//{"Született: " + this.props.player.birthdate.toLocaleDateString()}
        />
        <CardActionArea>
          <CardMedia image={this.props.player.imageURL? this.props.player.imageURL : ""} classes={{root: "card-media"}}>{nopicture}</CardMedia>
        </CardActionArea>
      </Card>
    );
  }
}

export default ImgCard;