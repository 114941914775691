import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import * as React from 'react';
import { PlayerAppearance } from '../reducers/MatchResultReducer';

interface IProps {
    lineup: Array<PlayerAppearance>
}

interface IState {
}

class Lineups extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {

        }
    }

    public render() {
        let rows = this.props.lineup?.sort((a,b) => a.jerseyNumber - b.jerseyNumber).map(player => {
            return <TableRow hover={true} classes={{root: "nonclickable"}}>
                <TableCell padding="checkbox" align="center">{player.jerseyNumber}</TableCell>
                <TableCell>{player.name}</TableCell>
                <TableCell padding="checkbox" align="center">{player.points}</TableCell>
                <TableCell padding="checkbox" align="center">{player.assists}</TableCell>
            </TableRow>
        })
        return (
            <Paper classes={{root: "lineup-table"}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow classes={{root: "nonclickable"}}>
                                <TableCell padding="checkbox" align="center">Mezszám</TableCell>
                                <TableCell>Játékos</TableCell>
                                <TableCell padding="checkbox" align="center">Pont</TableCell>
                                <TableCell padding="checkbox" align="center">Assziszt</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }
}

export default Lineups;