import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import { ValidationError } from "./RegisterForm";

interface IProps {
    handleChange: Function,
    lastname: string,
    firstname: string,
    motherslastname: string,
    mothersfirstname: string,
    reportErrorState: Function,
    validationerrors: Map<string, ValidationError>
}

class NameForm extends React.Component<IProps> {

    lastnamecheck = (identifier: string, value: string) => {
        if (value === "" || value === null) {
            this.props.reportErrorState("lastnameerror", true, "Mező kitöltése kötelező");
        }
        else if (/[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöőõøùúûüűųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖŐÕØÙÚÛÜŰŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]/.test(value)) {
            this.props.reportErrorState("lastnameerror", true, "Nem megfelelő karakter");
        }
        else if (value.length > 30) {
            this.props.reportErrorState("lastnameerror", true, "Túl hosszú név");
        }
        else {
            this.props.reportErrorState("lastnameerror", false, "");
        }
        this.props.handleChange(identifier, value);
    }

    firstnamecheck = (identifier: string, value: string) => {
        if (value === "" || value === null) {
            this.props.reportErrorState("firstnameerror", true, "Mező kitöltése kötelező");
        }
        else if (/[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöőõøùúûüűųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖŐÕØÙÚÛÜŰŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]/.test(value)) {
            this.props.reportErrorState("firstnameerror", true, "Nem megfelelő karakter");
        }
        else if (value.length > 30) {
            this.props.reportErrorState("firstnameerror", true, "Túl hosszú név");
        }
        else {
            this.props.reportErrorState("firstnameerror", false, "");
        }
        this.props.handleChange(identifier, value);
    }

    mothersLastNameCheck = (identifier: string, value: string) => {
        if (value === "" || value === null) {
            this.props.reportErrorState("motherslastnameerror", true, "Mező kitöltése kötelező");
        }
        else if (/[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöőõøùúûüűųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖŐÕØÙÚÛÜŰŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]/.test(value)) {
            this.props.reportErrorState("motherslastnameerror", true, "Nem megfelelő karakter");
        }
        else if (value.length > 30) {
            this.props.reportErrorState("motherslastnameerror", true, "Túl hosszú név");
        }
        else {
            this.props.reportErrorState("motherslastnameerror", false, "");
        }
        this.props.handleChange(identifier, value);
    }

    mothersFirstNameCheck = (identifier: string, value: string) => {
        if (value === "" || value === null) {
            this.props.reportErrorState("mothersfirstnameerror", true, "Mező kitöltése kötelező");
        }
        else if (/[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöőõøùúûüűųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖŐÕØÙÚÛÜŰŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]/.test(value)) {
            this.props.reportErrorState("mothersfirstnameerror", true, "Nem megfelelő karakter");
        }
        else if (value.length > 30) {
            this.props.reportErrorState("mothersfirstnameerror", true, "Túl hosszú név");
        }
        else {
            this.props.reportErrorState("mothersfirstnameerror", false, "");
        }
        this.props.handleChange(identifier, value);
    }

    render() {
        return (
            <Container>
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <TextField
                            name="lastname"
                            value={this.props.lastname}
                            onChange={e => this.lastnamecheck(e.target.name, e.target.value)}
                            fullWidth
                            color="primary"
                            error={this.props.validationerrors.get("lastnameerror")?.value}
                            helperText={this.props.validationerrors.get("lastnameerror")?.message}
                            label="Vezetéknév" />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            name="firstname"
                            value={this.props.firstname}
                            onChange={e => this.firstnamecheck(e.target.name, e.target.value)}
                            fullWidth
                            color="primary"
                            error={this.props.validationerrors.get("firstnameerror")?.value}
                            helperText={this.props.validationerrors.get("firstnameerror")?.message}
                            label="Keresztnév" />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <TextField
                            fullWidth
                            label="Anyja vezetékneve"
                            value={this.props.motherslastname}
                            name="motherslastname"
                            onChange={e => this.mothersLastNameCheck(e.target.name, e.target.value)} //trim, de ha van trim nincs space
                            color="primary"
                            error={this.props.validationerrors.get("motherslastnameerror")?.value}
                            helperText={this.props.validationerrors.get("motherslastnameerror")?.message} />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            fullWidth
                            label="Anyja keresztneve"
                            value={this.props.mothersfirstname}
                            name="mothersfirstname"
                            onChange={e => this.mothersFirstNameCheck(e.target.name, e.target.value)} //trim, de ha van trim nincs space
                            color="primary"
                            error={this.props.validationerrors.get("mothersfirstnameerror")?.value}
                            helperText={this.props.validationerrors.get("mothersfirstnameerror")?.message} />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default NameForm;