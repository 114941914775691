import { User } from "../../reducers/UserReducer";

export interface IUserLoginSuccessAction {
    type: userActions.LOGIN_SUCCESS,
    user: User,
}

export interface IUserLoginFailureAction {
    type: userActions.LOGIN_FAILURE,
    error: any,
}

export interface IUserLogoutAction {
    type: userActions.LOGOUT
}

export interface IUserHideError {
    type: userActions.HIDE_ERROR
}

export type LoginActions = IUserLoginSuccessAction | IUserLoginFailureAction | IUserLogoutAction | IUserHideError;

export interface IUserState {
    loggedinuser: User,
    error: string,
}

export enum userActions{
    LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    LOGOUT = 'USERS_LOGOUT',
    HIDE_ERROR = 'HIDE_ERROR'
};


export enum backdropActions{
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
};

export type BackDropActions = IBackdropOpenAction | IBackdropCloseAction;

export interface IBackdropOpenAction {
    type: backdropActions.OPEN,
}

export interface IBackdropCloseAction {
    type: backdropActions.CLOSE,
}