import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton, Grid, Typography } from "@material-ui/core";

interface IProps {
    open: boolean,
    handleClose: Function,
    confirmAction: Function,
}

interface IState {
}

class ConfirmDialog extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
        }
    }

    handleClose = () => {
        this.props.handleClose();
    }

    render() {
        return (
            <Dialog fullWidth onClose={() => this.handleClose()} open={this.props.open} >
                <DialogTitle>
                    <Grid container justify="space-between">
                        <Grid item>Törlés</Grid>
                        <Grid item><IconButton onClick={() => this.handleClose()}>X</IconButton></Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography>Biztosan törli az elemet?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.handleClose()}
                        classes={{ root: "red" }}>
                        Mégsem
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.confirmAction();
                            this.handleClose()
                        }}
                        classes={{ root: "green" }} autoFocus>
                        Törlés
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ConfirmDialog;