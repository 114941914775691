import { Reducer } from "react";
import { IMatchResultState, MatchResultActions, MatchResultActionTypes } from "../actions/types/MatchResultActionTypes";

export class MatchResult {
    id: number = 0;
    round: number = 0;
    season: number = 0;
    homeTeam: string = "";
    awayTeam: string = "";
    homeScore: number = 0;
    awayScore: number = 0;
    homeLineUp: Array<PlayerAppearance> = new Array<PlayerAppearance>();
    awayLineUp: Array<PlayerAppearance> = new Array<PlayerAppearance>();
    homeGoals: Array<Point> = new Array<Point>();
    awayGoals: Array<Point> = new Array<Point>();
    date: Date = new Date();
    timeouts: Array<Timeout> = new Array<Timeout>();
    venue: string = "";
    startTime: Date = new Date();
    endTime: Date = new Date();
    homePicture: string = "";
    awayPicture: string = "";
}

export class PlayerAppearance {
    id: number = 0;
    playerId: number = 0;
    name: string = "";
    points: number = 0;
    assists: number = 0;
    jerseyNumber: number = 0;
    matchId: number = 0;
}

export enum Team {
    AWAY,
    HOME
}

export class Point {
    id: number = 0;
    scorer: string = "";
    assist: string = "";
    minute: number = 0;
    team: Team = Team.HOME;
}

export class Timeout {
    id: number = 0;
    team: string = "";
    minute: number = 0;
}

export var matchState = {
    seasonMatches: new Array<MatchResult>(),
    todaysMatches: new Array<MatchResult>()
}

export const MatchResultReducer: Reducer<IMatchResultState, MatchResultActions> = (state = matchState, action) => {
    switch (action.type) {
        case MatchResultActionTypes.GETMATCHRESULT:
            {
                let newSeasonMatches = new Array<MatchResult>();
                if (state.seasonMatches.length > 0) {
                    newSeasonMatches = state.seasonMatches.map((match: MatchResult) => {
                        if (match.id === action.matchResult.id) {
                            return action.matchResult;
                        }
                        else return match;
                    });
                }
                else newSeasonMatches.push(action.matchResult);
                return {
                    ...state,
                    seasonMatches: newSeasonMatches,
                }
            }
        case MatchResultActionTypes.GETSEASONMATCHES:
            {
                return {
                    ...state,
                    seasonMatches: action.matches,
                }
            }
        case MatchResultActionTypes.GETMATCHESTODAY:
            {
                return {
                    ...state,
                    todaysMatches: action.matches,
                }
            }
        default: return state;
    }
}
