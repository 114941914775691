import * as React from "react";
import { connect } from "react-redux";
import CardHolder from "./CardHolder";
import { SingleAssociation } from "../reducers/SingleAssociationReducer";
import { Paper, Container } from "@material-ui/core";
import Details from "./Details";
import { getPlayer } from "../actions/SingleAssociationActions";
import './SingleAssociation.css'
import { getPlayersFromAssociation } from "../actions/SingleAssociationActions";
import { Player } from "../reducers/PlayerReducer";
import { direct } from "../Routes";

interface IProps{
    association: SingleAssociation,
    getPlayer: Function,
    getPlayersFromAssociation: Function,
    playersFromAssociation: Array<Player>,
    pages: number
}

class SingleAssociationPage extends React.Component<IProps>{

    componentDidMount() {
        if(this.props.association.id === 0) direct("associations");
        this.props.getPlayersFromAssociation(this.props.association.id, 1);
    }
    
    render(){
        return(
            <Container className="cont" maxWidth="lg">
                <Paper elevation={3}>
                    <Details association={this.props.association}/>
                    <CardHolder 
                        players={this.props.playersFromAssociation} 
                        getPlayer={this.props.getPlayer} 
                        getPlayers={(pageNumber: number) => this.props.getPlayersFromAssociation(this.props.association.id, pageNumber)}
                        pages={this.props.pages}/>
                </Paper>
            </Container>
        );
    }
}

function mapStateToProps(state: any){
    return{
        association: state.SingleAssociationReducer.singleassociation,
        playersFromAssociation: state.SingleAssociationReducer.playersFromAssociation,
        pages: state.SingleAssociationReducer.playerPages
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        getPlayer: (id: number) => dispatch(getPlayer(id)),
        getPlayersFromAssociation: (associationId: number, pageNumber: number) => dispatch(getPlayersFromAssociation(associationId, pageNumber))
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
(SingleAssociationPage);