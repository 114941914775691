import { Reducer } from "react";
import { INotificationState, Notification } from "../actions/types/NotificationTypes";

export var notificationState : INotificationState = {
    message: "",
    severity: "info",
}

export const NotificationReducer: Reducer<INotificationState, Notification> = (state= notificationState, action) => {
    switch (action.type) 
    {
        case "FAILURE":
        {
            return {
                ...state,
                message: action.message || "",
                severity: "error",
            }   
        }
        case "SUCCESS":
        {
            return {
                ...state,
                message: action.message || "",
                severity: "success",
            }
        }
        case "WARNING":
        {
            return {
                ...state,
                message: action.message || "",
                severity: "warning",
            }
        }
        case "INFO":
        {
            return {
                ...state,
                message: action.message || "",
                severity: "info",
            }
        }
        case "DISAPPEAR":
        {
            return {
                ...state,
                message: "",
            }
        }
        default: return state;
    }
}