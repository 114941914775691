import React from "react";
import { Grid, TextField, Container/*, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox*/ } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ValidationError } from "./RegisterForm";
import { Association } from "../reducers/AssociationReducer";

interface IProps {
    handleChange: Function,
    association: number,
    checked: Array<boolean>,
    reportErrorState: Function,
    validationerrors: Map<string, ValidationError>,
    email: string,
    associations: Array<Association>,
}

class LicenceForm extends React.Component<IProps> {

    emailcheck = (identifier: string, value: string) => {
        if(value === "" || value === null)
        {
            this.props.reportErrorState("emailerror", true, "Mező kitöltése kötelező");
        }
        else if(/[^a-zA-Z0123456789 .@-]/.test(value))
        {
            this.props.reportErrorState("emailerror", true, "Nem megfelelő karakter");
        }
        else if(value.split("@").length !== 2 || !value.split("@")[1].includes("."))
        {
            this.props.reportErrorState("emailerror", true, "Nem megfelelő formátum");
        }
        else
        {
            this.props.reportErrorState("emailerror", false, "");
        }
        this.props.handleChange(identifier, value);
    }

    render() {
        let associations = this.props.associations;
        if(!associations?.some(x => x.id === -1)) associations?.push(new Association(-1, "Nem tag egyesület"));
        let selectedassociation = associations?.find(x => x.id === this.props.association);
        return (
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Email cím"
                            value={this.props.email}
                            name="email"
                            onChange={e => this.emailcheck(e.target.name, e.target.value.trim())}
                            color="primary"
                            error={this.props.validationerrors.get("emailerror")?.value}
                            helperText={this.props.validationerrors.get("emailerror")?.message} />
                    </Grid>
                </Grid>
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={12}>
                        <Autocomplete
                            autoComplete
                            includeInputInList
                            options={associations}
                            getOptionLabel={(option) => option.name}
                            value={selectedassociation}
                            onChange={(e: any, v: any) => this.props.handleChange("association", v.id)}
                            renderInput={(params) => <TextField {...params} onChange={e => this.props.handleChange("association", e.currentTarget.value)} label="Egyesület" color="primary" />} />
                    </Grid>
                </Grid>
                {/*<Grid container spacing={8}>
                    <Grid item sm={6}>
                        <FormLabel component="legend">Sportág</FormLabel>
                    </Grid>
                    <Grid item sm={6}>
                        <FormControl>
                            <FormGroup row>
                                <FormControlLabel control={<Checkbox name="ultimate" checked={this.props.checked[0]} onChange={e => this.props.handleChange(e.target.name, e.target.checked)} />} label="Ultimate" />
                                <FormControlLabel control={<Checkbox name="discgolf" checked={this.props.checked[1]} onChange={e => this.props.handleChange(e.target.name, e.target.checked)} />} label="Disc golf" />
                                <FormControlLabel control={<Checkbox name="freestyle" checked={this.props.checked[2]} onChange={e => this.props.handleChange(e.target.name, e.target.checked)} />} label="Freestyle" />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>*/}
            </Container>
        );
    }
}

export default LicenceForm;