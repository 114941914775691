import React from 'react';
import { connect } from 'react-redux';
import { changePassword/*, sendForgottenPasswordEmail as sendPasswordResetEmail*/ } from '../actions/UserActions';
import ChangePassword from './ChangePassword';
//import SendPasswordResetEmail from './SendPasswordResetEmail';

const ForgotPasswordPage: React.FC = (props: any) => {

    let content;
    let search_split = props.location.search.slice(1);
    let token_split = search_split?.split("=");
    if(token_split === undefined || token_split[0] !== "token" || token_split[1] === undefined) 
        content = <div></div>;//<SendPasswordResetEmail sendPasswordResetEmail={props.sendPasswordResetEmail}/>;
    else content = <ChangePassword changePassword = {props.changePassword} token={token_split[1]}/>;
    return (
        content
    );
}

function mapStateToProps(state: any) {
    return {
        
    }
}

const mapActionsToProps = (dispatch: any) => {
    return {
        //sendPasswordResetEmail: (email: string) => dispatch(sendPasswordResetEmail(email)),
        changePassword: (password: string, token: string) => dispatch(changePassword(password, token))
    }
};


export default connect(
    mapStateToProps,
    mapActionsToProps
)(ForgotPasswordPage);