import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, IconButton, Grid, ListItem, List, Paper } from "@material-ui/core";
import { PlayerAppearance } from "../reducers/MatchResultReducer";

interface IProps {
    open: boolean,
    handleClose: Function,
    addGoal: Function,
    lineUp: Array<PlayerAppearance>,
    minute: number,
}

interface IState {
    scorer: number,
    assist: number,
    minute: number,
}

class AddGoalDialog extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            scorer: 0,
            assist: 0,
            minute: 0,
        }
    }

    handleChange = (event: any) => {
        if (event.target.value >= 0 && event.target.value <= 150 && event.target.value % 1 === 0 ) {
            this.setState({
                minute: parseInt(event.target.value)
            });
        }
    }

    handleClose = () => {
        this.props.handleClose();
        this.setState({minute: 0, scorer: 0, assist: 0});
    }

    render() {
        let scoreLineup = new Array<JSX.Element>();
        let assistLineup = new Array<JSX.Element>();
        this.props.lineUp.sort((a,b) => a.jerseyNumber - b.jerseyNumber).forEach(player => {
            scoreLineup.push(<ListItem
                key={player.id}
                button
                selected={player.id === this.state.scorer}
                onClick={() => this.setState({ scorer: player.id })}
            >{player.jerseyNumber + " " + player.name}</ListItem>)
            assistLineup.push(<ListItem
                key={player.id}
                button
                selected={player.id === this.state.assist}
                onClick={() => this.setState({ assist: player.id })}
            >{player.jerseyNumber + " " + player.name}</ListItem>)
        });
        return (
            <Dialog fullWidth onClose={() => this.handleClose()} open={this.props.open} >
                <DialogTitle>
                    <Grid container justifyContent="space-between">
                        <Grid item>Pont hozzáadása</Grid>
                        <Grid item><IconButton onClick={() => this.handleClose()}>X</IconButton></Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container justifyContent="center">
                            <Grid item xs={4} sm={5}/>
                            <Grid item xs={3} sm={1}>
                                    <TextField
                                        classes={{root: "lineup-list"}}
                                        autoFocus
                                        name="minute"
                                        label="Perc"
                                        type="number"
                                        variant="standard"
                                        color="primary"
                                        value={this.state.minute === 0 ? this.props.minute : this.state.minute}
                                        onInput={(event) => this.handleChange(event)}
                                    />
                            </Grid>
                            <Grid item xs = {5}/>
                            <Grid item xs={6}>
                                <Paper classes={{ root: "lineup-list" }}>
                                    <List><ListItem><b>Assziszt</b></ListItem>{assistLineup}</List>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper classes={{ root: "lineup-list" }}>
                                    <List><ListItem><b>Pontszerző</b></ListItem>{scoreLineup}</List>
                                </Paper>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.handleClose()}
                        classes={{ root: "red" }}>
                        Mégsem
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.addGoal(this.state.scorer, this.state.assist, this.state.minute === 0 ? this.props.minute : this.state.minute); 
                            this.handleClose()}}
                        classes={{ root: "green" }} autoFocus>
                        Hozzáadás
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default AddGoalDialog;