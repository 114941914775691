import { Reducer } from "react";
import { ISinglePlayerState, SinglePlayerActions, SinglePlayerActionTypes } from "../actions/types/SinglePlayerActionTypes";
import { Licence } from "./AssociationAdminReducer";
import { Association } from "./AssociationReducer";

export class SinglePlayer{
    id: number = 0;
    firstname: string = "";
    lastname: string = "";
    birthdate: number = 0;
    imageURL: string = "";
    licences: Array<Licence> = new Array<Licence>();
    association: Association = new Association();
}

export class LicenceRequestingPlayer{
    lastName: string = "";
    firstName: string = "";
    mothersFirstName: string = "";
    mothersLastName: string = "";
    birthplace: string = "";
    birthdate: Date = new Date();
    sport: number = 0;
}

export var playerState = {
    singleplayer: new SinglePlayer(),
}

export const SinglePlayerReducer: Reducer<ISinglePlayerState, SinglePlayerActions> = (state= playerState, action) => {
    switch (action.type) 
    {
        case SinglePlayerActionTypes.GETPLAYER:
        {
            return {
                ...state,
                singleplayer: action.player
            }   
        }
        default: return state;
    }
}
  