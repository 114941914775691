import axios from "axios";
import { Dispatch } from "redux";
import { connectionUrl, requestOptions } from "../auth";
import { NotificationTypes } from "./types/NotificationTypes";
import { MatchResult, PlayerAppearance } from "../reducers/MatchResultReducer";
import { AssociationAdminActionTypes } from "./types/AssociationAdminActionTypes";
import { direct } from "../Routes";
import { Player } from "../reducers/PlayerReducer";
import { PlayerDisplay } from "../MatchSubmissionPage/MatchSubmissionPage";

export const getSubmittableMatches = (competitionId: number) => {
    return async (dispatch: Dispatch, getState: Function) => {
        await axios.get(`${connectionUrl}/associationadmin/submittablematches/${competitionId}`, requestOptions(getState()))
            .then(
                response => {
                    dispatch(success(
                        transformDate(response.data.homeMatches).sort((a,b) => a.date.getTime() - b.date.getTime()), 
                        transformDate(response.data.awayMatches).sort((a,b) => a.date.getTime() - b.date.getTime())
                        ))
                    }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(homeMatches: Array<MatchResult>, awayMatches: Array<MatchResult>) { return { type: AssociationAdminActionTypes.GETSUBMITTABLEMATCHES, matches: {homeMatches, awayMatches} } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

function transformDate(matches: Array<MatchResult>) {
    matches.forEach(match => {
        match.date = new Date(match.date);
    });
    return matches;
}

export const getSubmissionData = (matchId: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.get(`${connectionUrl}/associationadmin/submittablematch/${matchId}`, requestOptions(getState()))
            .then(
                response => {dispatch(success(response.data.players, response.data.submittedPlayers, matchId)); direct("matchsubmission")}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(players: Array<Player>, submittedPlayers: Array<PlayerAppearance>, matchId: number) { return { type: AssociationAdminActionTypes.GETSUBMITTABLEMATCH, match: {players: players, submittedPlayers: submittedPlayers, matchId: matchId} } }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const postSubmissionData = (matchId: number, players: Array<PlayerDisplay>) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.post(`${connectionUrl}/associationadmin/submittablematch/${matchId}/player`, players, requestOptions(getState()))
            .then(
                response => {dispatch(success(response.data))}
            )
            .catch(
                error => {dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))}
            );
    }
    function success(submittedPlayers: Array<PlayerAppearance>) { return { type: AssociationAdminActionTypes.POSTSUBMITTABLEMATCH, submittedPlayers: submittedPlayers} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const deleteSubmissionData = (ids: Array<number>) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.post(`${connectionUrl}/associationadmin/submittablematch/player/remove`, ids, requestOptions(getState()))
            .then(
                response => {dispatch(success(ids))}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(ids: number[]) { return { type: AssociationAdminActionTypes.REMOVESUBMITTABLEMATCH, ids: ids} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const updateSubmissionData = (player: PlayerDisplay) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.put(`${connectionUrl}/associationadmin/submittablematch/player`, player, requestOptions(getState()))
            .then(
                response => {dispatch(success(player.id, player.jerseyNumber))}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(id: number, jerseyNumber: number) { return { type: AssociationAdminActionTypes.UPDATEJERSEYNUMBER, id: id, jerseyNumber:  jerseyNumber} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}