import { Reducer } from "react";
import { IAssociationsState, AssociationsActions, AssociationsActionTypes } from "../actions/types/AssociationActionTypes";

export class Association{
    constructor(id?: number, name?: string)
    {
        if(id !== undefined) this.id = id;
        if(name !== undefined) this.name = name;
    }

    id: number = 0;
    name: string = "";
    founded: number = 0;
    imageURL: string = "";
    description: string = "";
    email: string = "";
    webaddress: string = "";
}

export var associationsState = {
    associations: new Array<Association>(),
}

export const AssociationsReducer: Reducer<IAssociationsState, AssociationsActions> = (state= associationsState, action) => {
    switch (action.type) 
    {
        case AssociationsActionTypes.GETALL:
        {
            return {
                ...state,
                associations: action.associations
            }   
        }
        default: return state;
    }
}
  