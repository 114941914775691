import { ProtectedPlayer } from "../../reducers/AssociationAdminReducer";
import { MatchResult, PlayerAppearance } from "../../reducers/MatchResultReducer";
import { Player } from "../../reducers/PlayerReducer";
import { SingleAssociation } from "../../reducers/SingleAssociationReducer";

export type AssociationAdminActions =   IGetAssociation | 
                                        IGetPlayers | 
                                        IUpdatedLicences | 
                                        IUploadImage | 
                                        IGetSubmittableMatches | 
                                        IGetSubmittableMatch | 
                                        IPostAddPlayers | 
                                        IRemovePlayers |
                                        IUpdateJerseyNumber;

export interface IGetAssociation {
    type: AssociationAdminActionTypes.GETASSOCIATION,
    players: Array<ProtectedPlayer>,
    association: SingleAssociation,
}

export interface IGetPlayers {
    type: AssociationAdminActionTypes.GETPLAYERS,
    players: Array<ProtectedPlayer>,
    playerCount: number
}

export interface IUpdatedLicences {
    type: AssociationAdminActionTypes.UPDATEDLICENCES,
    playerids: number[]
}

export enum AssociationAdminActionTypes {
    GETASSOCIATION = 'GETASSOCIATION',
    GETPLAYERS = 'GETPLAYERS',
    UPDATEDLICENCES = 'UPDATEDLICENCES',
    UPLOAD = 'UPLOAD',
    GETSUBMITTABLEMATCHES = 'GETSUBMITTABLEMATCHES',
    GETSUBMITTABLEMATCH = 'GETSUBMITTABLEMATCH',
    POSTSUBMITTABLEMATCH = 'POSTSUBMITTABLEMATCH',
    REMOVESUBMITTABLEMATCH = 'REMOVESUBMITTABLEMATCH',
    UPDATEJERSEYNUMBER = 'UPDATEJERSEYNUMBER',
}

export interface IAssociationAdminState {
    players: Array<ProtectedPlayer>,
    playerCount: number,
    association: SingleAssociation,
    submittableMatches: { homeMatches: Array<MatchResult>, awayMatches: Array<MatchResult> },
    submittableMatch: { players: Array<Player>, submittedPlayers: Array<PlayerAppearance> }
}

export interface IUploadImage {
    type: AssociationAdminActionTypes.UPLOAD,
    imageURL: string,
    playerid: number,
}

export interface IGetSubmittableMatches {
    type: AssociationAdminActionTypes.GETSUBMITTABLEMATCHES,
    matches: { homeMatches: Array<MatchResult>, awayMatches: Array<MatchResult>, matchId: number },
}

export interface IGetSubmittableMatch {
    type: AssociationAdminActionTypes.GETSUBMITTABLEMATCH,
    match: { players: Array<Player>, submittedPlayers: Array<PlayerAppearance>, matchId: number }
}

export interface IPostAddPlayers {
    type: AssociationAdminActionTypes.POSTSUBMITTABLEMATCH,
    submittedPlayers: Array<PlayerAppearance>,
}

export interface IRemovePlayers {
    type: AssociationAdminActionTypes.REMOVESUBMITTABLEMATCH,
    ids: number[],
}

export interface IUpdateJerseyNumber {
    type: AssociationAdminActionTypes.UPDATEJERSEYNUMBER,
    id: number,
    jerseyNumber: number,
}