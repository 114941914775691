import { Player } from "../../reducers/PlayerReducer";

export type PlayersActions = IGetAllPlayer;

export interface IGetAllPlayer {
    type: PlayersActionTypes.GETALL,
    players: Array<Player>,
    pages: number,
}

export enum PlayersActionTypes{
    GETALL = 'GETALL',
}

export interface IPlayersState {
    players: Array<Player>,
}