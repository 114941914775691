import { Container, Grid, TextField, Button } from '@material-ui/core';
import * as React from 'react';
import './ForgotPassword.css';

interface IState {
    password: string,
    confirmpassword: string,
}

interface IProps {
    changePassword: Function,
    token: string,
}

class ChangePassword extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            password: '',
            confirmpassword: '',
        };
    }

    handleChange = (event: any) => {
        if (event.target.name === "confirmpassword") {
            this.setState({confirmpassword: event.target.value });
        }
        else if (event.target.name === "password") {
            this.setState({ password: event.target.value });
        }
    }

    handleSubmit = () => {
        if(this.state.password === null || this.state.password === "") alert("Jelszó üres!");
        else if(this.state.password !== this.state.confirmpassword) alert("Jelszó nem egyezik!");
        else this.props.changePassword(this.state.password, this.props.token);
    }

    render() {
        return (
            <Container classes={{root: "margin"}}>
                    <Grid container direction="column" alignItems="center">
                        <TextField
                            classes={{ root: "field" }}
                            name="password"
                            label="Jelszó"
                            type="password"
                            variant="outlined"
                            color="primary"
                            value={this.state.password}
                            onInput={(event) => this.handleChange(event)}
                        />
                        <TextField
                            classes={{ root: "field" }}
                            name="confirmpassword"
                            label="Jelszó megerősítése"
                            type="password"
                            variant="outlined"
                            color="primary"
                            value={this.state.confirmpassword}
                            onInput={(event) => this.handleChange(event)}
                        />
                        <Button onClick={this.handleSubmit} variant="outlined" color="primary">Jelszó megváltoztatása</Button>
                    </Grid>
            </Container>
        );
    }
}

export default ChangePassword;