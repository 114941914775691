import * as React from 'react';
import './MatchResultPage.css';

interface IProps {
    homePicture: string,
    awayPicture: string,
}

class Banner extends React.Component<IProps>{

    public render() {
        return (
            <div>
                <img alt="homeTeam" className="banner-img" src={this.props.homePicture}/>
                <img alt="awayTeam" className="banner-img" src={this.props.awayPicture}/>
            </div>
        );
    }
}

export default Banner;