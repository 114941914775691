import * as React from 'react';
import { Paper, Grid, Typography, Avatar, ListItem, ListItemText, List, /*InputLabel, Select, MenuItem,*/ Button } from '@material-ui/core';
import { SinglePlayer } from '../reducers/SinglePlayerReducer';
import './Page.css';
import { getSportName } from '../reducers/AssociationAdminReducer';
import { direct } from '../Routes';

interface IProps {
    player: SinglePlayer,
    //sendRequest: Function,
}

interface IState {
    selectedLicence: number,
}

class Details extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedLicence: 0,
        }
    }

    public render() {
        //let profilepicture = (this.props.player.imageURL === "") ? "/profile.png" : this.props.player.imageURL;
        let licences = this.props.player.licences?.map(licence =>
            <ListItem key={licence.id}>
                <ListItemText primary={new Date(licence.validity).getFullYear()} secondary={getSportName(licence.sport)} />
            </ListItem>
        )
        //let profilepicture = <Image cloudName="dzonzfwfn" publicId="samples/ecommerce/shoes.png" width="300" />;
        return (
            <Paper classes={{ root: "paper-details" }} variant="outlined">
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4} md={3}>
                        <Avatar src={this.props.player.imageURL} classes={{ root: "detail-avatar card-avatar" }}>{this.props.player.lastname.slice(0, 1).toUpperCase() + " " + this.props.player.firstname.slice(0, 1).toUpperCase()}</Avatar>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        <Typography variant="h5">
                            {this.props.player.lastname + " " + this.props.player.firstname}
                        </Typography>
                        <Typography variant="subtitle1">
                            Születési év: {this.props.player.birthdate}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={7} md={8} >
                        <Typography variant="h6">
                            Versenyengedélyek
                        </Typography>
                        <List>
                            {licences}
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4}>
                        {this.props.player.association ? "" :
                            /*<Grid item xs={12} sm={4} md={3}>
                                <InputLabel id="demo-simple-select-label">{new Date().getFullYear()}. évi engedély</InputLabel>
                                <Select
                                    value={this.state.selectedLicence}
                                    onChange={(e: any) => this.setState({selectedLicence: e.target.value})}
                                    style={{marginRight: "10px", marginTop: "10px"}}
                                >
                                    <MenuItem value={0}>Ultimate</MenuItem>
                                    <MenuItem value={1}>Disc golf</MenuItem>
                                    <MenuItem value={2}>Freestyle</MenuItem>
                                </Select>
                                <Button  variant="contained" color="primary" size="small" onClick={() => this.props.sendRequest(this.props.player.id, this.state.selectedLicence)}>Igénylés</Button>
                            </Grid>*/
                            <Button
                                variant="outlined"
                                disabled={this.props.player.licences.find(l => new Date(l.validity).getFullYear() === new Date().getFullYear()) ? true : false}
                                onClick={() => direct("licence-request")}>
                                {new Date().getFullYear()}. évi engedély igénylése
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default Details;