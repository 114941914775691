import { createStore, Store, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import reducer from "./reducers/CombinedReducers";
import dynamicMiddlewares from 'redux-dynamic-middlewares';

export default function configureStore(): Store {
  const store = createStore(reducer, undefined, applyMiddleware(thunk, dynamicMiddlewares));
  
  return store;
}
