import * as React from 'react';
import { Card, CardActionArea, CardHeader, /*Avatar, */CardMedia } from '@material-ui/core';
import { Association } from '../reducers/AssociationReducer';
import "./ImgCard.css";
import { PersonOutline } from '@material-ui/icons';

interface IProps{
  association: Association,
  getAssociation: any
}

class ImgCard extends React.Component<IProps> {

  handleclick() {
    this.props.getAssociation(this.props.association.id);
  }

  public render() {
    //let firstletter = this.props.association.name.slice(0,1).toUpperCase();
    let nopicture = this.props.association.imageURL? "" : <PersonOutline classes={{root: "person-icon"}} color="disabled"/>;
    return (
      <Card classes={{root: "card"}} elevation={4} onClick={() => this.handleclick()}>
        <CardHeader
          //avatar={<Avatar classes={{root: "card-avatar"}}> {firstletter} </Avatar>}
          title={this.props.association.name}
          subheader={"Alapítás éve: " + this.props.association.founded}
        />
        <CardActionArea>
          <CardMedia classes={{root: "card-media"}} image={this.props.association.imageURL? this.props.association.imageURL : ""}>{nopicture}</CardMedia>
        </CardActionArea>
      </Card>
    );
  }
}

export default ImgCard;