import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: '#ff8e53',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#fe6b8b',
      contrastText: '#000000',
    },
  },
  typography: {
    h2: {
      fontSize: '2rem',
      fontWeight: 300,
      '@media (min-width:600px)': {
        fontSize: '3.75rem',
        fontWeight: 300
      },
    },
    h6: {
      fontSize: '0.8rem',
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: '1.2rem',
        fontWeight: 500
      },
    },
    body1: {
      fontSize: '0.75rem',
      fontWeight: 500,
      '@media (min-width:600px)': {
        fontSize: '1rem',
        fontWeight: 400
      },
    },
    body2: {
      fontSize: '0.7rem',
      fontWeight: 400,
      '@media (min-width:600px)': {
        fontSize: '0.875rem',
        fontWeight: 400
      },
    }
  }
});