import { PlayerStat, Team } from "../../reducers/CompetitionReducer";

export type CompetitionActions = IGetLeagueTable | IGetPlayerStats;

export interface IGetLeagueTable {
    type: CompetitionActionTypes.GETLEAGUETABLE,
    leagueTable: Array<Team>,
}

export interface IGetPlayerStats {
    type: CompetitionActionTypes.GETPLAYERSTATS,
    playerStats: Array<PlayerStat>,
}

export enum CompetitionActionTypes{
    GETLEAGUETABLE = 'GETLEAGUETABLE',
    GETPLAYERSTATS = 'GETPLAYERSTATS'
}

export interface ICompetitionState {
    leagueTable: Array<Team>,
    playerStats: Array<PlayerStat>
}



