import { AppBar, Tabs, Tab, Grid } from '@material-ui/core';
import * as React from 'react';
import { MatchResult } from '../reducers/MatchResultReducer';
import LineUps from './LineUps';
import './MatchResultPage.css';
import TimeLine from './TimeLine';

interface IProps {
    matchResult: MatchResult,
}

interface IState {
    value: number,
}

class MatchDetails extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: 0,
        }
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            value: newValue,
        });
    };

    public render() {
        let matchEnded = Math.floor((this.props.matchResult.endTime?.getTime() - this.props.matchResult.startTime?.getTime()) / 60000) + 1;
        return (
            <AppBar position="static" color="default">
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Idővonal" value={0} />
                    <Tab label="Felállások" value={1} />
                </Tabs>
                {this.state.value === 0 &&
                    this.props.matchResult.awayGoals &&
                    this.props.matchResult.homeGoals &&
                    this.props.matchResult.timeouts ?
                    <TimeLine awayPoints={this.props.matchResult.awayGoals}
                        homePoints={this.props.matchResult.homeGoals}
                        timeouts={this.props.matchResult.timeouts}
                        homeTeam={this.props.matchResult.homeTeam}
                        awayTeam={this.props.matchResult.awayTeam}
                        matchEnded={matchEnded} /> :
                    <Grid container justifyContent="space-between">
                        <Grid xs={12} sm={6} item><LineUps lineup={this.props.matchResult.homeLineUp} /></Grid>
                        <Grid xs={12} sm={6} item><LineUps lineup={this.props.matchResult.awayLineUp} /></Grid>
                    </Grid>
                }
            </AppBar>
        );
    }
}

export default MatchDetails;