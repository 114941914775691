import { combineReducers } from 'redux';
import { AssociationsReducer } from './AssociationReducer';
import { UserReducer } from './UserReducer';
import { SingleAssociationReducer } from './SingleAssociationReducer';
import { SinglePlayerReducer } from './SinglePlayerReducer';
import { PlayersReducer } from './PlayerReducer';
import { AssociationAdminReducer } from './AssociationAdminReducer';
import { SystemAdminReducer } from './SystemAdminReducer';
import { BackdropReducer } from './BackdropReducer';
import { NotificationReducer } from './NotificationReducer';
import { MatchResultReducer } from './MatchResultReducer';
import { CompetitionReducer } from './CompetitionReducer';
import { MatchControlReducer } from './MatchControlReducer';

export default combineReducers({
    AssociationsReducer: AssociationsReducer,
    UserReducer: UserReducer,
    SingleAssociationReducer: SingleAssociationReducer,
    SinglePlayerReducer: SinglePlayerReducer,
    PlayersReducer: PlayersReducer,
    AssociationAdminReducer: AssociationAdminReducer,
    SystemAdminReducer: SystemAdminReducer,
    BackdropReducer: BackdropReducer,
    NotificationReducer: NotificationReducer,
    MatchResultReducer: MatchResultReducer,
    CompetitionReducer: CompetitionReducer,
    MatchControlReducer: MatchControlReducer,
});