import * as React from 'react';
import { Container, Grid } from '@material-ui/core';
import ImgCard from './ImgCard';
import { Player } from '../reducers/PlayerReducer';
import { Pagination } from '@material-ui/lab';

interface IProps {
    players: Array<Player>,
    getPlayer: Function,
    pages: number,
    getPlayers: Function,
}

interface IState {
    pagenumber: number
}

class CardHolder extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            pagenumber: 1,
        }
    }

    handlePageSelect = (page: number) => {
        this.setState({pagenumber: page});
        this.props.getPlayers(page);
    }

    public render() {
        var cardlist = Array(5);
        for (let i = 0; i < this.props.players.length; i++) {
            cardlist[i] = <ImgCard key={"card" + i} player={this.props.players[i]} getPlayer={this.props.getPlayer} />
        }
        return (
            <Container>
                <Grid container justifyContent="space-evenly">{cardlist}</Grid>
                <Pagination classes={{ ul: "pagination" }} count={this.props.pages} size="large" page={this.state.pagenumber} onChange={(e, p) => this.handlePageSelect(p)} />
            </Container>
        );
    }
}

export default CardHolder;