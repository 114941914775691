import { Reducer } from "react";
import { ICompetitionState, CompetitionActions, CompetitionActionTypes } from "../actions/types/CompetitionActionTypes";

export class Team {
    name: string = "";
    shortname: string = "";
    win: number = 0;
    loss: number = 0;
    pointsFor: number = 0;
    pointsAgainst: number = 0;
    played: number = 0;
    picture: string = "";
}

export class PlayerStat {
    name: string = "";
    points: number = 0;
    assists: number = 0;
    playerId: number = 0;
    played: number = 0;
}

export var competitionState = {
    leagueTable: new Array<Team>(),
    playerStats: new Array<PlayerStat>()
}

export const CompetitionReducer: Reducer<ICompetitionState, CompetitionActions> = (state = competitionState, action) => {
    switch (action.type) {
        case CompetitionActionTypes.GETLEAGUETABLE:
            {
                return {
                    ...state,
                    leagueTable: action.leagueTable
                }
            }
        case CompetitionActionTypes.GETPLAYERSTATS:
            {
                return {
                    ...state,
                    playerStats: action.playerStats
                }
            }
        default: return state;
    }
}
