import * as React from 'react';
import { Paper, Grid, Typography, Avatar } from '@material-ui/core';
import { SingleAssociation } from '../reducers/SingleAssociationReducer';
import './SingleAssociation.css'

interface IProps{
    association: SingleAssociation
}

class Details extends React.Component<IProps>{

    public render() {
        return (
            <Paper classes={{root: "details-paper"}} variant="outlined">
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={4} md={3}>
                    <Avatar src={this.props.association.imageURL} classes={this.props.association.imageURL? {root: "detail-avatar"} : {root:"detail-avatar card-avatar"}}> {this.props.association.name.slice(0,1)} </Avatar>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Typography variant="h5">
                        Név: {this.props.association.name}
                    </Typography>
                    <Typography variant="h5">
                        Alapítva: {this.props.association.founded}
                    </Typography>
                </Grid>
            </Grid>
            </Paper>
        );
    }
}

export default Details;