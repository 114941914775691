import * as React from 'react';
import { Button, Container, Stepper, Step, StepContent, StepButton, StepConnector, StepIcon, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import LicenceForm from './LicenceForm';
import NameForm from './NameForm';
import BirthdataForm from './BirthdataFrom';
import AddressForm from './AddressForm';
import "./PlayerRegister.css";
import { RegisteringPlayer } from '../reducers/PlayerReducer';
import { registerPlayer } from '../actions/PlayerActions';
import { connect } from 'react-redux';
import { getAllAssociation } from '../actions/AssociationActions';
import { Association } from '../reducers/AssociationReducer';

export class ValidationError {
    value: boolean = false;
    message: string = "";
}

interface IState {
    player: RegisteringPlayer,
    step: number,
    completedsteps: Set<number>,
    licences: Array<boolean>,
    errors: Map<string, ValidationError>,
    dialogopen: boolean,
    dialogtitle: string,
    dialogcontent: string,
}

interface IProps {
    registerPlayer: Function,
    getAllAssociation: Function,
    associations: Array<Association>,
}

class RegisterForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            player: new RegisteringPlayer(),
            step: 0,
            completedsteps: new Set<number>(),
            licences: new Array<boolean>(3).fill(false),
            errors: new Map<string, ValidationError>([
                ["lastnameerror", new ValidationError()],
                ["firstnameerror", new ValidationError()],
                ["motherslastnameerror", new ValidationError()],
                ["mothersfirstnameerror", new ValidationError()],
                ["birthdateerror", new ValidationError()],
                ["birthplaceerror", new ValidationError()],
                ["postcodeerror", new ValidationError()],
                ["streetnameerror", new ValidationError()],
                ["streetnumbererror", new ValidationError()],
                ["settlementerror", new ValidationError()],
                ["additionalerror", new ValidationError()],
            ]),
            dialogopen: false,
            dialogcontent: "",
            dialogtitle: "",
        };
    }

    componentDidMount()
    {
        this.props.getAllAssociation();
    }

    handleChange = (property: string, value: any) => {
        this.setState(prevState => {
            let player = Object.assign({}, prevState.player);
            let licences = Object.assign({}, prevState.licences);
            switch (property) {
                case "email": player.emailaddress = value; break;
                case "association": player.association = value; break;
                case "lastname": player.lastname = value; break;
                case "firstname": player.firstname = value; break;
                case "birthdate": player.birthdate = value; break;
                case "birthplace": player.birthplace = value; break;
                case "gender": player.gender = value; break;
                case "motherslastname": player.motherslastname = value; break;
                case "mothersfirstname": player.mothersfirstname = value; break;
                case "postcode": player.address.postcode = parseInt(value); break;
                case "settlement": player.address.settlement = value; break;
                case "streetsuffix": player.address.streetsuffix = value; break;
                case "streetname": player.address.streetname = value; break;
                case "streetnumber": player.address.streetnumber = value; break;
                case "additional": player.address.additional = value; break;
                case "ultimate": licences[0] = value; break;
                case "discgolf": licences[1] = value; break;
                case "freestyle": licences[2] = value; break;
                default: throw new Error('Unknown data');
            }
            return { player, licences };
        })
    }

    checkValidationErrors = (): boolean => {
        //no licences check
        let errorsfound: number = 0;
        this.state.errors.forEach(error => {
            if (error.value === true) errorsfound ++;
        });
        Object.values(this.state.player).forEach(property => {
            if(property === "" || property === 0) errorsfound++;
        });
        if(this.state.player.address.additional === "") errorsfound--;
        Object.values(this.state.player.address).forEach(property => {
            if(property === "" || property === 0) errorsfound++;
        });
        if(new Date(this.state.player.birthdate).getFullYear() >= new Date().getFullYear()) errorsfound++;
        if(errorsfound > 0) return true;
        else return false;
    }

    handleSubmit = () => {
        if (this.checkValidationErrors()) {
            this.setState({
                dialogopen: true,
                dialogcontent: "Kérem ellenőrizze a kitöltendő mezőket, mert előfordulhat, hogy azok nem, vagy hibásan lettek kitöltve.",
                dialogtitle: "Sikertelen regisztráció",
            })
        }
        else {
            let player = Object.assign({}, this.state.player);
            if (this.state.licences[0] === true) player.licences.push(0);
            if (this.state.licences[1] === true) player.licences.push(1);
            if (this.state.licences[2] === true) player.licences.push(2);
            player.firstname = player.firstname.trim();
            player.lastname = player.lastname.trim();
            player.birthplace = player.birthplace.trim();
            player.emailaddress = player.emailaddress.trim();
            player.motherslastname = player.motherslastname.trim();
            player.mothersfirstname = player.mothersfirstname.trim();
            player.address.streetname = player.address.streetname.trim();
            player.address.settlement = player.address.settlement.trim();
            player.address.streetnumber = player.address.streetnumber.trim();
            player.address.additional = player.address.additional.trim();
            this.props.registerPlayer(player);
            this.setState({ 
                player: new RegisteringPlayer(),
            });
        }
    }

    reportErrorState = (identifier: string, value: boolean, message: string) => {
        let newerrors: Map<string, ValidationError> = new Map<string, ValidationError>(this.state.errors);
        let newerror = new ValidationError();
        newerror.value = value;
        newerror.message = message;
        newerrors.set(identifier, newerror);
        this.setState({
            errors: newerrors,
        })
    }

    getStepContent() {
        switch (this.state.step) {
            case 0:
                return <LicenceForm
                    association={this.state.player.association}
                    email={this.state.player.emailaddress}
                    checked={this.state.licences}
                    handleChange={this.handleChange}
                    reportErrorState={this.reportErrorState}
                    validationerrors={this.state.errors}
                    associations={this.props.associations} />;
            case 1:
                return <NameForm
                    lastname={this.state.player.lastname}
                    firstname={this.state.player.firstname}
                    motherslastname={this.state.player.motherslastname}
                    mothersfirstname={this.state.player.mothersfirstname}
                    handleChange={this.handleChange}
                    reportErrorState={this.reportErrorState}
                    validationerrors={this.state.errors} />;
            case 2:
                return <BirthdataForm
                    date={this.state.player.birthdate}
                    place={this.state.player.birthplace}
                    gender={this.state.player.gender}
                    handleChange={this.handleChange}
                    reportErrorState={this.reportErrorState}
                    validationerrors={this.state.errors} />;
            case 3:
                return <AddressForm
                    address={this.state.player.address}
                    handleChange={this.handleChange}
                    reportErrorState={this.reportErrorState}
                    validationerrors={this.state.errors} />;
            default:
                throw new Error('Unknown step');
        }
    }

    handleStep = (newstep: number) => {
        this.setState({ step: newstep });
    };

    handleDialogClose = () => {
        this.setState({ dialogopen: false });
    };

    render() {
        let steps = ["Versenyengedély adatok", "Személyes adatok", "Születési adatok", "Lakcím adatok"];
        return (
            <Container classes={{ root: "container-alignment" }} maxWidth="sm">
                <form>
                    <Stepper nonLinear activeStep={this.state.step} orientation="vertical"
                        connector={<StepConnector
                            classes={{ active: "active-stepper", completed: "completed-stepper", line: "line-stepper" }} />}
                    >
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton
                                    icon={<StepIcon
                                        active={index === this.state.step}
                                        //completed={this.state.completedsteps.has(index)}
                                        icon={index + 1}
                                        classes={{ active: "active-stepper" }} />}
                                    onClick={e => this.handleStep(index)}
                                >{label}
                                </StepButton>
                                <StepContent classes={{ root: "border-gradient" }}>
                                    {this.getStepContent()}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>

                    <Button variant="outlined" color="primary" onClick={this.handleSubmit} classes={{ root: 'button', label: 'button-label' }}>Küldés</Button>
                </form>

                <Dialog open={this.state.dialogopen} onClose={this.handleDialogClose}>
                    <DialogTitle>{this.state.dialogtitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.dialogcontent}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary" autoFocus>Bezárás</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        );
    }
}

function mapStateToProps(state: any){
    return{
        associations: state.AssociationsReducer.associations,
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        registerPlayer: (player: RegisteringPlayer) => dispatch(registerPlayer(player)),
        getAllAssociation: () => dispatch(getAllAssociation()),
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps
)(RegisterForm);