import axios from "axios";
import { Dispatch } from "redux";
import { connectionUrl } from "../auth";
import { PlayersActionTypes } from "./types/PlayerActionTypes";
import { Player, RegisteringPlayer } from "../reducers/PlayerReducer";
import { NotificationTypes } from "./types/NotificationTypes";
import { LicenceRequestingPlayer } from "../reducers/SinglePlayerReducer";

export const getAllPlayer = () => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.get(`${connectionUrl}/player`)
            .then(
                response => {dispatch(success(response.data.players, response.data.pages))}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(players: Array<Player>, pages: number) {return { type: PlayersActionTypes.GETALL, players, pages} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const findPlayers = (pagenumber: number, searchexpression: string) => {
    let search: string = "";
    if(searchexpression !== undefined && searchexpression !== "" && searchexpression !== null) 
    {
        search = "&searchexpression=" + searchexpression;
    };
    let page: string = "pagenumber=" + pagenumber;
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.get(`${connectionUrl}/player?${page}${search}`)
            .then(
                response => {dispatch(success(response.data.players, response.data.pages))}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(players: Array<Player>, pages: number) {return { type: PlayersActionTypes.GETALL, players, pages} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const registerPlayer = (player: RegisteringPlayer) => {
    return async (dispatch: Dispatch) =>{
        await axios.post(`${connectionUrl}/player`, player)
            .then(
                response => {dispatch(success(response.data))}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(message: any) {return {type: NotificationTypes.SUCCESS, message: message}}
    function failure(error: any) {return {type: NotificationTypes.FAILURE, message: error}}
}

/*function transformDate(jsonplayers: Array<Player>) {
    let players = new Array<Player>();
    jsonplayers.forEach(
        function (jsonplayer){
            let player: Player = {
                ...jsonplayer,
                birthdate: new Date(jsonplayer.birthdate),
            }
            players.push(player);
        }
    );
    return players;
}*/

export const sendNonAssociationRequest = (player: LicenceRequestingPlayer) => {
    return async (dispatch: Dispatch) => {
        await axios.post(`${connectionUrl}/player/request`, player)
            .then(
                response => {dispatch(success(response.data))}
                
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(message: any) {return {type: NotificationTypes.SUCCESS, message: message}}
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const confirmRequest = async (searchstring: string) => {
    await axios.get(`${connectionUrl}/player/request-confirmation${searchstring}`);
}