import { Reducer } from "react";
import { ISystemAdminState, SystemAdminActions, SystemAdminActionTypes } from "../actions/types/SystemAdminActionTypes";
import { ProtectedPlayer } from "./AssociationAdminReducer";
import { Association } from "./AssociationReducer";
import { Player } from "./PlayerReducer";

export class Request{
    id: number = 0;
    creationdate: Date = new Date();
    state: number = 0;
    action: string = "";
    association: Association = new Association();
    player: Player = new Player();
    similarity: number = 0;
    sport: number = 0;
}

export class Similarity{
    firstNameSimilarity: number = 0;
    lastNameSimilarity: number = 0;
    birthDateSimilarity: number = 0;
    birthPlaceSimilarity: number = 0;
    genderSimilarity: number = 0;
    mothersNameSimilarity: number = 0;
    similarPlayer: ProtectedPlayer = new ProtectedPlayer();
    registeringPlayer: ProtectedPlayer = new ProtectedPlayer();
}

export var systemAdminState: ISystemAdminState = {
    requests: new Array<Request>(),
    similarity: new Similarity(),
}

export const SystemAdminReducer: Reducer<ISystemAdminState, SystemAdminActions> = (state= systemAdminState, action) => {
    switch (action.type)
    {
        case SystemAdminActionTypes.GETALLREQUEST:
        {
            return {
                ...state,
                requests: action.requests
            }   
        }
        case SystemAdminActionTypes.CHANGEREQUESTSTATE:
        {
            let requests = state.requests.filter(request => request.id !== action.id);
            return {
                ...state,
                requests: requests,
            }
        }
        case SystemAdminActionTypes.GETSIMILARITY:
        {
            return {
                ...state,
                similarity: action.similarity,
            }
        }
        default: return state;
    }
}