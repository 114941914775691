export function auth(state: any) {
    
    let user = state.UserReducer.loggedinuser;
    if (user && user.token) {
        return 'Bearer ' + user.token;
    } else {
        return null;
    }
}

export function requestOptions(state: any)
{
    let Authorization = auth(state);
    return {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization
        }
    }
}

export const connectionUrl = process.env.REACT_APP_APPSERVER;