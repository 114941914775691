import axios from "axios";
import { Dispatch } from "redux";
import { connectionUrl, requestOptions } from "../auth";
import { AssociationAdminActionTypes } from "./types/AssociationAdminActionTypes";
import { ProtectedPlayer, Licence } from "../reducers/AssociationAdminReducer";
//import { SingleAssociation } from "../reducers/SingleAssociationReducer";
import { NotificationTypes } from "./types/NotificationTypes";
import { backdropActions } from "./types/UserActionTypes";
//import { Association } from "../reducers/AssociationReducer";

/*export const getAssociation = () => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.get(`${connectionUrl}/associationadmin/association`, requestOptions(getState()))
            .then(
                response => {
                    let players = transformDate(response.data.players);
                    response.data.players = [];
                    dispatch(success(response.data, players));
                }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(association: SingleAssociation, players: Array<ProtectedPlayer>) {return { type: AssociationAdminActionTypes.GETASSOCIATION, players, association} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}*/

export const getPlayers = (page: number) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.get(`${connectionUrl}/associationadmin/players?pagenumber=${page || 1}`, requestOptions(getState()))
            .then(
                response => {
                    let players = transformDate(response.data.players);
                    dispatch(success(players, response.data.playerCount));
                }
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(players: Array<ProtectedPlayer>, playerCount: number) {return { type: AssociationAdminActionTypes.GETPLAYERS, players, playerCount} }
    function failure(error: any) { return { type: NotificationTypes.FAILURE, message: error } }
}

export const sendRequest = (playerids: number[], sport: number ) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        dispatch({ type: backdropActions.OPEN });
        await axios.post(`${connectionUrl}/associationadmin/request`, playerids, requestOptions(getState()))
            .then(
                response => {
                    dispatch({ type: backdropActions.CLOSE });
                    dispatch(success(response.data));
                    dispatch(writeData(response.data))                
                }
            )
            .catch(
                error => {
                    dispatch({ type: backdropActions.CLOSE });
                    dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
                }
            );
    }
    function success(response: number[]) {
        let message = "Sikeres megújítás " + playerids.length + "/" + response.length + " fő számára.";
        let type = NotificationTypes.SUCCESS;
        if(playerids.length !== response.length) type = NotificationTypes.WARNING;
        
        return {type: type, message: message}
    }
    function writeData(playerids: number[]) {return { type: AssociationAdminActionTypes.UPDATEDLICENCES, playerids}}
    function failure(error: any) {return {type: NotificationTypes.FAILURE, message: error}}
}

function transformDate(jsonplayers: Array<ProtectedPlayer>) {
    let players = new Array<ProtectedPlayer>();
    jsonplayers.forEach(
        function (jsonplayer){
            let licences = new Array<Licence>();
            jsonplayer.licences.forEach(
                function (jsonlicence){
                    let licence: Licence ={
                        ...jsonlicence,
                        validity: new Date(jsonlicence.validity),
                    }
                    licences.push(licence);
                }
            );
            let player: ProtectedPlayer = {
                ...jsonplayer,
                sportsmedicallicense: new Date(jsonplayer.sportsmedicallicense),
                licences: licences,
            }
            players.push(player);
        }
    );
    return players;
}

/*export const uploadImage = (playerid: number, image: string) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.post(`${connectionUrl}/associationadmin/image/${playerid}`, {image: image}, requestOptions(getState()))
            .then(
                response => {dispatch(success(response.data));}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(imageURL: string) {return {type: AssociationAdminActionTypes.UPLOAD, imageURL: imageURL, playerid: playerid}}
    function failure(error: any) {return {type: NotificationTypes.FAILURE, message: error}}
}

export const updateAssociationData = (association: Association) => {
    return async (dispatch: Dispatch, getState: Function) =>{
        await axios.put(`${connectionUrl}/associationadmin/association`, association, requestOptions(getState()))
            .then(
                response => {dispatch(success(response.data))}
            )
            .catch(
                error => dispatch(failure(error.response?.data || "Szolgáltalás nem üzemel"))
            );
    }
    function success(response: any) {return {type: NotificationTypes.SUCCESS, message: response}}
    function failure(error: any) {return {type: NotificationTypes.FAILURE, message: error}}
}*/