import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

interface IProps {
    open: boolean,
    handleClose: Function,
    handleAccept: Function,
    who: string,
}

class NewLicenceDialog extends React.Component<IProps>{

    render() {
        return (
            <Dialog onClose={() => this.props.handleClose()} open={this.props.open}>
                <DialogTitle>Új versenyengedély igénylése</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Versenyengedély igénylése {this.props.who} részére {new Date().getFullYear()}. évi szezonra.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => this.props.handleClose()} 
                        color="primary">
                        Mégsem
                    </Button>
                    <Button 
                        onClick={() => this.props.handleAccept()} 
                        color="primary" autoFocus>
                        Igen
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default NewLicenceDialog;