import { Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import * as React from 'react';
import './MatchResultPage.css';

interface IProps {
    homeScore: number,
    awayScore: number,
    startTime: Date,
    endTime: Date,
    date: Date,
}

interface IState {
    interval: number,
    minute: number,
}

class Scores extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            interval: 0,
            minute: Math.floor((new Date().getTime() - this.props.startTime?.getTime()) / 60000) + 1
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.startTime !== this.props.startTime) {
            this.setState({ minute: Math.floor((new Date().getTime() - this.props.startTime?.getTime()) / 60000) + 1 })
        }
    }

    componentDidMount() {
        let interval = +global.setInterval(() => this.tick(), 60000);
        this.setState({
            interval: interval,
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    tick = () => {
        let delayBetween = this.props.startTime?.getSeconds() - new Date().getSeconds();
        if(delayBetween < 0) delayBetween += 60; 
        if (new Date("0001-01-01T00:00:00").getFullYear() !== this.props.startTime?.getFullYear() && new Date("0001-01-01T00:00:00").getFullYear() === this.props.endTime?.getFullYear()) {
            setTimeout(() => 
            this.setState({
                minute: Math.floor((new Date().getTime() - this.props.startTime?.getTime()) / 60000) + 1
            }), (delayBetween + 1) * 1000);
        }
    }
    
    public render() {
        let matchNotStarted = new Date("0001-01-01T00:00:00").getFullYear() === this.props.endTime?.getFullYear() && new Date("0001-01-01T00:00:00").getFullYear() === this.props.startTime?.getFullYear();
        let matchEnded = new Date("0001-01-01T00:00:00").getFullYear() !== this.props.endTime?.getFullYear() && new Date("0001-01-01T00:00:00").getFullYear() !== this.props.startTime?.getFullYear();
        let date = new Date(this.props.date);
        return (
            <Grid container spacing={3} justifyContent="center" alignItems="center" classes={{root: "scores-grid"}}>
                <Grid item xs={5} md={3} classes={{ root: "aligncenter" }}>
                    <Paper elevation={2} classes={{ root: "paper" }}>
                        <Typography variant="h2">{this.props.homeScore}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={2} sm={2} classes={{ root: "aligncenter" }}>
                    {(matchNotStarted || matchEnded) ?
                        window.innerWidth < 600 ?
                        <Grid container>
                            <Grid item xs={12}><Typography data-testid='date' variant="body2">{date.getFullYear() + "."}</Typography></Grid>
                            <Grid item xs={12}><Typography data-testid='date' variant="body2">{this.addZeroToSingleDigitTime(date.getMonth() + 1) + "." + this.addZeroToSingleDigitTime(date.getDate()) + "."}</Typography></Grid>
                            <Grid item xs={12}><Typography data-testid='date' variant="body2">{this.addZeroToSingleDigitTime(date.getHours()) + ":" + this.addZeroToSingleDigitTime(date.getMinutes())}</Typography></Grid>
                        </Grid>
                        : <Typography data-testid='date' variant="body2">{date.toLocaleDateString() + " " + date.toLocaleTimeString()}</Typography>
                        :
                        <div>
                            <Typography data-testid='minutes' color="primary" variant="h6">{(this.state.minute) + "'"}</Typography>
                            <LinearProgress color="primary" classes={{ root: "progressbar" }} />
                        </div>
                    }
                </Grid>
                <Grid item xs={5} md={3} classes={{ root: "aligncenter" }}>
                    <Paper elevation={2} classes={{ root: "paper" }}>
                        <Typography variant="h2">{this.props.awayScore}</Typography>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    addZeroToSingleDigitTime = (x: number) => {
        return x < 10 ? "0" + x : x;
    }
}

export default Scores;