import * as React from "react";
import { connect } from "react-redux";
import { Paper, Container } from "@material-ui/core";
import Scores from "./Scores";
import Banner from "./Banner";
import MatchDetails from "./MatchDetails";
import { MatchResult } from "../reducers/MatchResultReducer";
import { getMatchResult, stopConnection } from "../actions/MatchResultActions";

interface IProps{
    getMatchResult: Function,
    matchResult: MatchResult,
    match: any,
}

class MatchResultPage extends React.Component<IProps>{

    componentDidMount() {
        this.props.getMatchResult(this.props.match.params.id);
    }

    componentWillUnmount = () => {
        stopConnection(this.props.matchResult.id);
    }

    render(){
        return(
            <Container className="cont" maxWidth="lg">
                {this.props.matchResult? <Paper elevation={3}>
                    <Banner homePicture={this.props.matchResult.homePicture} awayPicture={this.props.matchResult.awayPicture}/>
                    <Scores homeScore={this.props.matchResult.homeScore}
                            awayScore={this.props.matchResult.awayScore}
                            startTime={this.props.matchResult.startTime}
                            endTime={this.props.matchResult.endTime}
                            date={this.props.matchResult.date}/>
                    <MatchDetails matchResult={this.props.matchResult}/>
                </Paper> : ""}
            </Container>
        );
    }
}

function mapStateToProps(state: any, props: IProps){
    return{
        matchResult: state.MatchResultReducer.seasonMatches.find((match: MatchResult) => match.id === parseInt(props.match.params.id)),
    }
}

const mapActionToProps = (dispatch: any) => {
    return {
        getMatchResult: (matchId: number) => dispatch(getMatchResult(matchId)),
    };
};

export default connect(
    mapStateToProps,
    mapActionToProps)
(MatchResultPage);